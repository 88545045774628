import axios from 'axios';

export function getMintingStatus({ address }: { address: string }) {
  console.log(address);
  return axios
    .get<{ data: { mint: number } }>(
      `https://admin.api.therecharge.io/minting/piggycell/${address}/status`
    )
    .then((resp) => resp.data.data);
}

export function completeMint({ txid, address }: { txid: string; address: string }) {
  return axios
    .post(`https://admin.api.therecharge.io/minting/piggycell/${address}/status`, {
      txid,
    })
    .then((resp) => resp.data.data);
}
