import React, { useEffect, useRef, useState } from 'react';
import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';

import popup_video from '../../../assets/video/car_main.mp4';
import close_btn from '../../../assets/images/btn_menu-close.png';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { homeModal } from '../../../store/atoms';
import classNames from 'classnames';

const piggyQnaLists = [
  {
    order: '01',
    question: 'What is the minting price?',
    answer: <p>There will be total of 1,000 Piggy Cells available to mint</p>,
  },

  {
    order: '02',
    question: 'How many Piggycell will be minted?',
    answer: <p>There will be total of 1,000 Piggycells available to mint</p>,
  },

  {
    order: '03',
    question: 'How can I mint Piggy Cell?',
    answer: (
      <p>
        You can connect your Phantom Wallet on thePiggy Cell NFT and have sufficient SOL ready in
        your wallet before proceeding to the mint. Once minting is activated you will be able to see
        the ‘Mint’ button which will guide you into the minting page.
      </p>
    ),
  },

  {
    order: '04',
    question: 'Is there a limit?',
    answer: <p>You can mint as many NFTs as you can as far as it is available to mint.</p>,
  },

  {
    order: '05',
    question: 'Will there be a secondary market?',
    answer: <p>You can trade your Piggy Cell NFT secondary market on Solana</p>,
  },

  {
    order: '06',
    question: 'Wll there be royalties?',
    answer: (
      <p>
        Yes, there is. 2.5% of royalty goes to The Recharge Incentive Hub to incentivize RCG holders
      </p>
    ),
  },

  {
    order: '07',
    question: 'Is there a rarity system?',
    answer: <p>Yes, there is. You can check the rarity ratio in attribute section</p>,
  },

  {
    order: '08',
    question: 'Which network  Piggy Cell NFT works?',
    answer: <p>Piggy Cell NFT is compatible with Solana network</p>,
  },

  {
    order: '09',
    question: 'Why Solana? / Why Ethereum?',
    answer: (
      <p>
        Piggy Cell NFT is launched on Solana to help users mint and trade their NFTs at an efficient
        cost and effective way. We are aware of the importance of Ethereum compatibility however we
        do not want our users to pay more on Transaction Fee than NFT itself. In the long-run, we
        are also planning to provide multi-chain NFT solutions.
      </p>
    ),
  },
];

const QnaLists = [
  {
    order: '01',
    question: 'What is the minting price?',
    answer: (
      <p>
        The 1st edition <b>“The Recharge Neon Car”</b> costs 0.5 SOL however each edition’s minting
        price differs, and it depends on the benefits and utilities added on the NFT <br />
      </p>
    ),
  },

  {
    order: '02',
    question: 'How many Neon Cars will be minted?',
    answer: <p>There will be total of 1,000 Neon cars available to mint</p>,
  },

  {
    order: '03',
    question: 'How can I mint Neon Cars?',
    answer: (
      <p>
        You can connect your Phantom Wallet on The Recharge NFT and have sufficient SOL ready in
        your wallet before proceeding the mint. Once minting is activated you will be able to see
        the “Mint” button which will guide you into the minting page.
      </p>
    ),
  },

  {
    order: '04',
    question: 'Is there a limit?',
    answer: <p>You can mint as many NFTs as you can as far as it is available to mint.</p>,
  },

  {
    order: '05',
    question: 'Will there be a secondary market?',
    answer: <p>You can trade your Recharge NFT secondary market on Solana</p>,
  },

  {
    order: '06',
    question: 'Wll there be royalites?',
    answer: (
      <p>
        Yes, there is. 2.5% of royalty goes to The Recharge Incentive Hub to incentivize RCG holders
      </p>
    ),
  },

  {
    order: '07',
    question: 'Is there a rarity system?',
    answer: <p>Yes, there is. You can check the rarity ratio in attribute section</p>,
  },

  {
    order: '08',
    question: 'Which network TheRechargeNFT works?',
    answer: <p>The Recharge NFT is compatible with Solana network</p>,
  },

  {
    order: '09',
    question: 'Why Solana? / Why Ethereum?',
    answer: (
      <p>
        The Recharge NFT is launched on Solana to help users mint and trade their NFTs at efficient
        cost and effective way. We are aware of the importance of Ethereum compatibility however we
        do not want our users to pay more on Transaction Fee than NFT itself. In a long-run, we are
        also planning to provide multi-chain NFT solutions
      </p>
    ),
  },
];

const HomeSectionSix = () => {
  const [select, setSelect] = useState('');
  const [modal, setModal] = useRecoilState(homeModal);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const location = useHistory().location;
  const [currentTab, setCurrentTab] = useState('piggy');

  const toggle = () => {
    setModal(!modal);
  };

  const removePopupClass = () => {
    const bodyElement = document.querySelector('body')!;
    bodyElement.classList.remove('pop-open');
  };

  useEffect(() => {
    const bodyElement = document.querySelector('body')!;
    bodyElement.classList.remove('pop-open');
    if (location.hash) {
      setModal(false);
    }
  }, []);

  // useEffect(() => {
  //   bootstrap();
  // },[])
  //
  // const bootstrap = async () => {
  //   videoRef!.current!.volume = 0.1;
  //   videoRef!.current?.addEventListener('click', () => {
  //     videoRef!.current!.muted = !videoRef!.current?.muted;
  //   })
  // }
  console.log(currentTab, 'c');
  return (
    <>
      <section className="feature05" id="marketplace">
        <strong>All in one NFT marketplace on Magic Eden.</strong>
        <a
          className="btn-full"
          style={{ cursor: 'pointer' }}
          target="_blank"
          href="https://magiceden.io/marketplace/recharge_neon_car_httpsnfttherechargeio"
        >
          More
        </a>
      </section>

      <section className="feature06" id="faq">
        <h2 className="tit">FAQ</h2>
        <div className="faq-wrap">
          <ul className="tab">
            <li
              className={classNames({ curr: currentTab === 'piggy' })}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setCurrentTab('piggy');
              }}
            >
              <a>Edition 2</a>
            </li>
            <li
              className={classNames({ curr: currentTab === 'recharge' })}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setCurrentTab('recharge');
              }}
            >
              <a>Edition 1</a>
            </li>
          </ul>
          {currentTab === 'piggy' ? (
            <ul className="qna-list">
              {piggyQnaLists.map((item, i) => (
                <li
                  key={item.order}
                  className={select === item.order ? 'on' : ''}
                  style={{ transition: 'height 2s' }}
                  onClick={(e) => {
                    const liText = e.currentTarget.textContent as string;
                    setSelect(liText.slice(0, 2));
                    if (select && select === item.order) {
                      setSelect('');
                    }
                  }}
                >
                  <div className="question">
                    <a>
                      <em>{item.order}</em>
                      {item.question}
                    </a>
                  </div>
                  <SlideDown className={'my-dropdown-slidedown'}>
                    <div className="answer">
                      <p>{item.answer}</p>
                    </div>
                  </SlideDown>
                </li>
              ))}
            </ul>
          ) : (
            <ul className="qna-list">
              {QnaLists.map((item, i) => (
                <li
                  key={item.order}
                  className={select === item.order ? 'on' : ''}
                  style={{ transition: 'height 2s' }}
                  onClick={(e) => {
                    const liText = e.currentTarget.textContent as string;
                    setSelect(liText.slice(0, 2));
                    if (select && select === item.order) {
                      setSelect('');
                    }
                  }}
                >
                  <div className="question">
                    <a>
                      <em>{item.order}</em>
                      {item.question}
                    </a>
                  </div>
                  <SlideDown className={'my-dropdown-slidedown'}>
                    <div className="answer">
                      <p>{item.answer}</p>
                    </div>
                  </SlideDown>
                </li>
              ))}
            </ul>
          )}
        </div>
      </section>
      {/*              <video autoPlay={true} muted={true} loop={true} playsInline={true} id="bgvid" preload="auto" width="100%" height="100%">
                <source src="https://nft.therecharge.io/video/car.mp4" type="video/mp4" />
              </video>*/}
      {/*{modal && (*/}
      {/*  <div className="popup-wrap">*/}
      {/*    <a*/}
      {/*      className="pop-close"*/}
      {/*      onClick={() => {*/}
      {/*        setModal(false);*/}
      {/*        removePopupClass();*/}
      {/*      }}*/}
      {/*      style={{ cursor: 'pointer' }}*/}
      {/*    >*/}
      {/*      <img src={close_btn} alt="" />*/}
      {/*    </a>*/}
      {/*    <div className="dim" style={{ background: 'rgba(0, 0, 0, .8)' }} />*/}
      {/*    <div className="pop-video">*/}
      {/*      <video*/}
      {/*        autoPlay*/}
      {/*        muted*/}
      {/*        loop*/}
      {/*        playsInline*/}
      {/*        preload="auto"*/}
      {/*        width="100%"*/}
      {/*        height="100%"*/}
      {/*        ref={videoRef}*/}
      {/*        controls*/}
      {/*        src="https://s3.us-east-2.amazonaws.com/nft.therecharge.io/video/car_popup.mp4"*/}
      {/*        controlsList="nodownload"*/}
      {/*      >*/}
      {/*        /!*<source src={popup_video} type="video/mp4" />*!/*/}
      {/*      </video>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*)}*/}
    </>
  );
};

export default HomeSectionSix;
