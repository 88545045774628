import React from 'react';
import ReactCountDown, { zeroPad } from 'react-countdown';
import moment from 'moment';
import momentTimeZone from 'moment-timezone';
import { useRecoilState } from 'recoil';
import { isPossibleMintAtom } from '../../../store/atoms';
import { DDAY } from '../../../constant';
import useModal from '../../../hooks/useModal';
import NftListModal from '../../templetes/Modal/nftListModal';

const CountDown = () => {
  // const dDay = '2022-01-26 13:00:00';
  // const dDay = '2022-01-27 09:00:00';
  const { ModalPortal, openModal, closeModal } = useModal();
  // const CompletedTime = () => (
  //     return <> </>
  //   // <div style={{ marginTop: '20px' }}>
  //   //   <a
  //   //     className="btn-full"
  //   //     style={{ cursor: 'pointer' }}
  //   //     onClick={() => {
  //   //       openModal();
  //   //       openModal();
  //   //       const bodyElement = document.querySelector('body')!;
  //   //       bodyElement.classList.add('pop-open');
  //   //     }}
  //   //   >
  //   //     Neon Cars
  //   //   </a>
  //   // </div>
  //   //   <div>
  //   //       <span className="emo">⚡</span>
  //   //       <span className="time">
  //   //         <em className="day">{zeroPad(days)}</em>d : <em className="hour">{zeroPad(hours)}</em>h
  //   //         : <em className="minute">{zeroPad(minutes)}</em>m :{' '}
  //   //           <em className="second">{zeroPad(seconds)}</em>s
  //   //       </span>
  //   //   </div>
  // );
  const krTime = momentTimeZone(DDAY).tz('Asia/Seoul').format('YYYY-MM-DD HH:mm:ss');

  const localTime = moment(DDAY).format('YYYY-MM-DD HH:mm:ss');
  const differ = moment(krTime).valueOf() - moment(localTime).valueOf();

  const [isPossibleMint, setIsPossibleMint] = useRecoilState(isPossibleMintAtom);

  const renderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
    completed: boolean;
  }) => {
    if (completed) {
      console.log('true');
      setIsPossibleMint(true);
      // Render a completed state
      // return (
      //   <div>
      //     <span className="emo">⚡</span> {" "}
      //     <span className="time">
      //       <em className="day">00</em>d : <em className="hour">00</em>h
      //       : <em className="minute">00</em>m :{' '}
      //       <em className="second">00</em>s
      //     </span>
      //   </div>
      // )
      // return <CompletedTime />;
      return (
        <div>
          <span className="emo">⚡</span>
          <span className="time">
            <em className="day">{zeroPad(days)}</em>d : <em className="hour">{zeroPad(hours)}</em>h
            : <em className="minute">{zeroPad(minutes)}</em>m :{' '}
            <em className="second">{zeroPad(seconds)}</em>s
          </span>
        </div>
      );
    } else {
      // Render a countdown
      return (
        // <div>
        //   <span className="emo">⚡</span> {" "}
        //   <span className="time">
        //     <em className="day">00</em>d : <em className="hour">00</em>h
        //     : <em className="minute">00</em>m :{' '}
        //     <em className="second">00</em>s
        //   </span>
        // </div>
        <div>
          <span className="emo">⚡</span>
          <span className="time">
            <em className="day">{zeroPad(days)}</em>d : <em className="hour">{zeroPad(hours)}</em>h
            : <em className="minute">{zeroPad(minutes)}</em>m :{' '}
            <em className="second">{zeroPad(seconds)}</em>s
          </span>
        </div>
      );
    }
  };
  //moment(localTime).valueOf() - differ
  return (
    <>
      <ReactCountDown date={moment(localTime).valueOf() - differ} renderer={renderer}>
        <div />
      </ReactCountDown>

      <ModalPortal>
        <NftListModal closeModal={closeModal} />
      </ModalPortal>
    </>
  );
};

export default CountDown;
