import HomeSectionOne from '../../Components/templetes/Home/SectionOne';
import HomeSectionTwo from '../../Components/templetes/Home/SectionTwo';
import HomeSectionThree from '../../Components/templetes/Home/SectionThree';
import HomeSectionFour from '../../Components/templetes/Home/SectionFour';
import HomeSectionFive from '../../Components/templetes/Home/SectionFive';
import HomeSectionSix from '../../Components/templetes/Home/SectionSix';
import React, { useEffect } from 'react';
import useScrollToHash from '../../hooks/useScrollToHash';
import { useRecoilState } from 'recoil';
import { headerTitle } from '../../store/atoms';
import { useHistory } from 'react-router-dom';

const HomePage = () => {
  const [_, setTitle] = useRecoilState(headerTitle);
  const location = useHistory().location;
  useEffect(() => {
    const listenToScroll = () => {
      const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
      if (winScroll < 530) {
        setTitle('HOME');
      }
    };
    if (location.pathname === '/') {
      window.addEventListener('scroll', listenToScroll);
    }
    return () => window.removeEventListener('scroll', listenToScroll);
  }, []);
  useScrollToHash();
  return (
    <main>
      <div id="contents">
        {/*<HomeHeader />*/}
        <HomeSectionOne />
        <HomeSectionTwo />
        <HomeSectionThree />
        <HomeSectionFour />
        <HomeSectionFive />
        <HomeSectionSix />
        {/*<HomeFooter />*/}
      </div>
    </main>
  );
};

export default HomePage;
