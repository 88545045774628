import { useEffect } from 'react';
import { routes } from '../common/constants';

export default function useScrollToHash() {
  useEffect(() => {
    //TODO: setTimeout with 0 made it work in Safari - i dont know why
    setTimeout(() => {
      // const { A} = routes;
      // const pathArr = ['/a'];
      const { hash } = window.location;
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          // @ts-ignore
          element.scrollIntoView();
        }
      }
    }, 50);
  }, []);
}
