import grdi_left01 from '../../../../assets/images/bg_gradi_left01.png';
import gradi_left03 from '../../../../assets/images/bg_gradi_left03.png';
import gradi_right01 from '../../../../assets/images/bg_gradi_right01.png';

const gradiBoxProp = [
  { name: 'left01 left', img: grdi_left01 },
  { name: 'left03 left', img: gradi_left03 },
  { name: 'right01 right', img: gradi_right01 },
  { name: 'right02 right', img: gradi_right01 },
];

const GradiBox = () => {
  return (
    <div className="gradi-box">
      {gradiBoxProp.map((item, i) => (
        <div className={item.name} key={i}>
          <img src={item.img} alt="" />
        </div>
      ))}
    </div>
  );
};

export default GradiBox;
