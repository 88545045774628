import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import manual_pdf from '../../../assets/pdf/manual.pdf';
import { CircularProgress } from '@material-ui/core';
import { GatewayStatus, useGateway } from '@civic/solana-gateway-react';
import { useEffect, useState } from 'react';
import { CandyMachineAccount } from '../../../candy-machine';

const tDiv = styled.div`
  > button {
    border-radius: 56px;
  }
`;

export const CTAButton = styled(Button)`
  //padding: 0 35px;
  border-radius: 56px !important;
  box-shadow: 0 13px 17px 0 rgba(119, 87, 239, 0.2);
  -webkit-box-shadow: 0 13px 17px 0 rgba(119, 87, 239, 0.2);
  -ms-box-shadow: 0 13px 17px 0 rgba(119, 87, 239, 0.2);
  background-image: linear-gradient(94deg, #3461ff 8%, #8454eb 93%);
  font-size: 16px;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: 0.32px;
  color: #fff;
  float: none;
  display: block;
  width: 200px;
  margin: 32px auto 0;
  height: 48px;
  padding: 8px 16px !important;
  text-transform: none !important;
  > span {
    margin-top: 0;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: 0.32px;
    > div {
      margin-top: 19px !important;
    }
  }
`; // add your own styles here

const FooterWrapper = styled.div`
  margin-top: 20px;
  a {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: 0.32px;
  }
`;

export const MintButton = ({
  onMint,
  candyMachine,
  isMinting,
}: {
  onMint: () => Promise<void>;
  candyMachine?: CandyMachineAccount;
  isMinting: boolean;
}) => {
  const { requestGatewayToken, gatewayStatus } = useGateway();
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    if (gatewayStatus === GatewayStatus.ACTIVE && clicked) {
      onMint();
      setClicked(false);
    }
  }, [gatewayStatus, clicked, setClicked, onMint]);

  const getMintButtonContent = () => {
    if (candyMachine?.state.isSoldOut) {
      return 'Sold Out';
    } else if (isMinting) {
      return <CircularProgress />;
    } else if (candyMachine?.state.isPresale) {
      return 'Presale Mint';
    }

    return 'Mint';
  };

  return (
    <>
      <CTAButton
        disabled={candyMachine?.state.isSoldOut || isMinting || !candyMachine?.state.isActive}
        onClick={async () => {
          setClicked(true);
          if (candyMachine?.state.isActive && candyMachine?.state.gatekeeper) {
            if (gatewayStatus === GatewayStatus.ACTIVE) {
              setClicked(true);
            } else {
              await requestGatewayToken();
            }
          } else {
            await onMint();
            setClicked(false);
          }
        }}
        variant="contained"
      >
        {getMintButtonContent()}
      </CTAButton>

      <FooterWrapper>
        <a
          href="pdf/manual.pdf"
          target="_blank"
          rel="noopener noreferrer"
          style={{ borderBottom: '1px solid #fff' }}
        >
          How to mint
        </a>
      </FooterWrapper>
    </>
  );
};
