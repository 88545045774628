import left01_img from '../../../assets/images/bg_gradi_left01.png';
import left02_img from '../../../assets/images/bg_gradi_left02.png';
import left03_img from '../../../assets/images/bg_gradi_left03.png';
import right01_img from '../../../assets/images/bg_gradi_right01.png';
import right02_img from '../../../assets/images/bg_gradi_right02.png';

import right03_img from '../../../assets/images/bg_gradi_right03.png';

import kv_logo from '../../../assets/images/kv_logo.png';
import CountDown from '../../atoms/CountDown';
import artwork_img from '../../../assets/images/img_artwork-frame.png';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useRecoilState } from 'recoil';
import { headerTitle } from '../../../store/atoms';
import { useHistory } from 'react-router-dom';

const DivWrapper = styled.div`
  @media (max-width: 1280px) {
    width: 345px !important;
    height: 350px !important;
  }
`;

const HomeSectionOne = () => {
  return (
    <>
      <article id="test">
        <div className="gradi-box">
          <div className="left01 left">
            <img src={left01_img} alt="" />
          </div>
          <div className="left02 left">
            <img src={left02_img} alt="" />
          </div>
          <div className="left03 left">
            <img src={left03_img} alt="" />
          </div>
          <div className="right01 right">
            <img src={right01_img} alt="" />
          </div>
          <div className="right02 right">
            <img src={right02_img} alt="" />
          </div>
          <div className="right03 right">
            <img src={right03_img} alt="" />
          </div>
        </div>
        <section className="kv">
          <div className="text">
            <strong>
              <img src={kv_logo} alt="The NFT Recharge" />
            </strong>
            {/*<span>Minting Time</span>*/}
            <CountDown />
          </div>
          <figure>
            {/*<img src={artwork_img} alt="" />*/}
            <span className="frame">
              <img src={artwork_img} alt="" />
            </span>
            <DivWrapper
              style={{ zIndex: 100, width: '570px', height: '550px', top: '0px', left: '-5px' }}
            >
              <video
                autoPlay={true}
                muted={true}
                loop={true}
                playsInline={true}
                id="bgvid"
                preload="auto"
                width="100%"
                height="100%"
              >
                <source
                  src="https://s3.us-east-2.amazonaws.com/nft.therecharge.io/video/main.mp4"
                  type="video/mp4"
                />
              </video>
            </DivWrapper>
          </figure>
        </section>
      </article>
    </>
  );
};

export default HomeSectionOne;
