import { useEffect, useState } from 'react';
import GradiBox from '../../Components/atoms/Box/Gradi';
import AttributeBox from '../../Components/atoms/Box/Attribute';
import useScrollToHash from '../../hooks/useScrollToHash';
import { useRecoilState } from 'recoil';
import { headerTitle } from '../../store/atoms';
import { useHistory } from 'react-router-dom';

const AttributePage = () => {
  const [_, setTitle] = useRecoilState(headerTitle);

  useEffect(() => {
    const bodyElement = document.querySelector('body')!;
    bodyElement.classList.remove('pop-open');
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 90);
  }, []);

  useScrollToHash();

  return (
    <>
      <main id="attributes">
        <div id="contents">
          <article>
            <GradiBox />
            <section className="attributes">
              <h3>NFT Recharge’s Attribute</h3>
              <AttributeBox type="recharge" />
            </section>
          </article>
        </div>
      </main>
    </>
  );
};

export default AttributePage;
