import { useRecoilState } from 'recoil';
import { isPathTrigger } from '../store/atoms';

type Handler = () => void;

const useTriggerHooks = (): [boolean, Handler] => {
  const [trigger, setTrigger] = useRecoilState(isPathTrigger);

  const onHandler = () => {
    setTrigger(!trigger);
  };

  return [trigger, onHandler];
};

export default useTriggerHooks;
