import { useEffect } from 'react';
import useScrollToHash from '../../hooks/useScrollToHash';
import GradiBox from '../../Components/atoms/Box/Gradi';
import AttributeBox from '../../Components/atoms/Box/Attribute';

const PiggyCellPage = ({ props }: any) => {
  useEffect(() => {
    const bodyElement = document.querySelector('body')!;
    bodyElement.classList.remove('pop-open');
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 90);
  }, []);

  useScrollToHash();

  return (
    <>
      <main id="attributes">
        <div id="contents">
          <article>
            <GradiBox />
            <section className="attributes">
              <h3>NFT PiggyCells Attribute</h3>
              <AttributeBox type="piggycells" />
            </section>
          </article>
        </div>
      </main>
    </>
  );
};

export default PiggyCellPage;
