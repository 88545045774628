import { attr2Lists, attrLists } from '../../../../constant';
import { FC } from 'react';
import styled from 'styled-components';

interface AttrType {
  type: 'recharge' | 'piggycells';
}

const EmWrapper = styled.em`
  right: 2px !important;
  bottom: 13px !important;
  font-size: 17px !important;
`;

const UlWrapper = styled.ul`
  padding: 23px !important;
  padding-left: 39px !important;
  li {
    width: 222px !important;
    img {
      width: 222px;
    }
  }
`;

const AttributeBox = ({ type }: AttrType) => {
  const lists = type === 'recharge' ? attrLists : attrLists;

  return (
    <>
      {type === 'recharge'
        ? attrLists.map((list, i) => (
            <div className="attri-box" key={i}>
              <strong>{list.title}</strong>

              <ul>
                {list.children.map((item, idx) => (
                  <li key={idx}>
                    <figure>
                      <img src={item.img} alt="" />
                    </figure>
                    <div className="txt">
                      <i className={item.iClassName}>{item.iText}</i>
                      <span>{item.spanText}</span>
                      <em className="per">{item.emText}</em>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ))
        : attr2Lists.map((list, i) => (
            <div className="attri-box" key={i}>
              <strong>{list.title}</strong>
              <div>
                <UlWrapper>
                  {list.children.map((item, idx) => (
                    <li key={idx}>
                      <figure>
                        <img src={item.img} alt="" />
                      </figure>
                      <div className="txt">
                        {/*<i className={item.iClassName}>{item.iText}</i>*/}
                        <span>{item.spanText.toUpperCase()}</span>
                        <EmWrapper className="per">{item.emText}</EmWrapper>
                      </div>
                    </li>
                  ))}
                </UlWrapper>
              </div>
            </div>
          ))}
    </>
  );
};

export default AttributeBox;
