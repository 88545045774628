import img_attributes from '../../../assets/images/img_attributes.png';
import img_attributes_m from '../../../assets/images/img_attributes-m.png';
import React from 'react';
import { Link } from 'react-router-dom';
import { routes } from '../../../common/constants';
import useTriggerHooks from '../../../hooks/useTriggerHooks';
import { useRecoilState } from 'recoil';
import { headerTitle } from '../../../store/atoms';
import mint_mid_gif from '../../../assets/images/latest_mint.gif';
import pig_gif from '../../../assets/images/Attributes_2.gif';
import styled from 'styled-components';

const LiWrapper = styled.li`
  @media (max-width: 1280px) {
    left: 0 !important;
    margin-top: 0 !important;
    top: 0;

    .text-sub {
      margin-top: 30px;
    }
  }
`;

const SpanWrapper = styled.span`
  padding-left: 28px;
  padding-top: 18px;
  img {
    width: 300px;
  }
`;

const HomeSectionFour = () => {
  const [trigger, onChangeTrigger] = useTriggerHooks();
  const [title, setTitle] = useRecoilState(headerTitle);

  return (
    <>
      <section className="feature03" id="attributes">
        <div className="inner-box">
          <ul>
            <li>
              <div className="text">
                <h2 className="tit">Attributes</h2>
                <span>Edition 2</span>
                <strong>PiggyCell</strong>
                <Link
                  to="/attributes/piggycell"
                  className="btn-full"
                  onClick={() => {
                    setTitle('attributes'.toUpperCase());
                    onChangeTrigger();
                  }}
                >
                  More
                </Link>
              </div>
              <figure style={{ display: 'block' }}>
                <span className="pc">
                  <div style={{ width: '916px', height: '916px' }}>
                    <img
                      src={pig_gif}
                      alt=""
                      style={{ position: 'relative', left: '195px', top: '84px' }}
                    />
                  </div>
                </span>
                <SpanWrapper className="mobile">
                  <img src={pig_gif} alt="" />
                </SpanWrapper>
              </figure>
            </li>

            <LiWrapper style={{ position: 'relative', left: '146px', marginTop: '210px' }}>
              <figure>
                <span className="pc">
                  <img style={{ display: 'block', width: '580px' }} src={mint_mid_gif} alt="" />
                </span>
                <span className="mobile">
                  <img style={{ display: 'block', width: '350px' }} src={mint_mid_gif} alt="" />
                </span>
              </figure>
              <div className="text text-sub" style={{ marginRight: '124px' }}>
                <span>Edition 1</span>
                <strong>Recharge Neon Cars</strong>
                <Link
                  to="attributes#attributes"
                  className="btn-full"
                  onClick={() => {
                    setTitle('attributes'.toUpperCase());
                    onChangeTrigger();
                  }}
                >
                  More
                </Link>{' '}
              </div>
            </LiWrapper>

            {/*<figure style={{ marginRight: '-20px', marginTop: '150px' }}>*/}
            {/*  <span className="pc">*/}
            {/*    <img style={{ display: 'block', width: '580px' }} src={mint_mid_gif} alt="" />*/}
            {/*  </span>*/}
            {/*  <span className="mobile">*/}
            {/*    <img style={{ display: 'block', width: '350px' }} src={mint_mid_gif} alt="" />*/}
            {/*  </span>*/}
            {/*</figure>*/}
          </ul>
        </div>
      </section>
    </>
  );
};

export default HomeSectionFour;
