import back_1 from '../assets/images/back_1.png';
import back_2 from '../assets/images/back_2.png';
import back_3 from '../assets/images/back_3.png';
import back_4 from '../assets/images/back_4.png';
import back_5 from '../assets/images/back_5.png';

import body_1 from '../assets/images/body_1.png';
import body_2 from '../assets/images/body_2.png';
import body_3 from '../assets/images/body_3.png';
import body_4 from '../assets/images/body_4.png';
import body_5 from '../assets/images/body_5.png';
import body_6 from '../assets/images/body_6.png';

import color_1 from '../assets/images/color_1.png';
import color_2 from '../assets/images/color_2.png';
import color_3 from '../assets/images/color_3.png';
import color_4 from '../assets/images/color_4.png';
import color_5 from '../assets/images/color_5.png';
import color_6 from '../assets/images/color_6.png';
import color_7 from '../assets/images/color_7.png';
import color_8 from '../assets/images/color_8.png';
import color_9 from '../assets/images/color_9.png';
import color_10 from '../assets/images/color_10.png';

import hood_0 from '../assets/images/hood_0.png';
import hood_1 from '../assets/images/hood_1.png';
import hood_2 from '../assets/images/hood_2.png';
import hood_3 from '../assets/images/hood_3.png';
import hood_4 from '../assets/images/hood_4.png';

import wheel_1 from '../assets/images/wheel_1.png';
import wheel_2 from '../assets/images/wheel_2.png';
import wheel_3 from '../assets/images/wheel_3.png';
import wheel_4 from '../assets/images/wheel_4.png';
import wheel_5 from '../assets/images/wheel_5.png';

import gloomycastle from '../assets/images/background_gloomycastle.png';
import gradient1 from '../assets/images/background_gradient1.png';
import gradient2 from '../assets/images/background_gradient2.png';
import gradient3 from '../assets/images/background_gradient3.png';
import gradient4 from '../assets/images/background_gradient4.png';
import gradient5 from '../assets/images/background_gradient5.png';
import gradient6 from '../assets/images/background_gradient6.png';
import graffittistreetart from '../assets/images/background_graffittistreetart.png';
import greekbeach from '../assets/images/background_greekbeach.png';
import mooninnightsky from '../assets/images/background_mooninnightsky.png';
import solid1 from '../assets/images/background_solid1.png';
import solid2 from '../assets/images/background_solid2.png';
import solid3 from '../assets/images/background_solid3.png';
import solid4 from '../assets/images/background_solid4.png';
import solid5 from '../assets/images/background_solid5.png';
import solid6 from '../assets/images/background_solid6.png';
import solid7 from '../assets/images/background_solid7.png';
import solid8 from '../assets/images/background_solid8.png';
import solid9 from '../assets/images/background_solid9.png';
import solid10 from '../assets/images/background_solid10.png';

import line from '../assets/images/shoes_3line.png';
import balletdance from '../assets/images/shoes_balletdance.png';
import basic_shoes from '../assets/images/shoes_basic.png';
import basicBoots from '../assets/images/shoes_basicboots.png';
import blueconverse from '../assets/images/shoes_blueconverse.png';
import clown from '../assets/images/shoes_clown.png';
import fancylaceboots from '../assets/images/shoes_fancylaceboots.png';
import formalleather from '../assets/images/shoes_formalleather.png';
import hawaiianleaf from '../assets/images/shoes_hawaiianleaf.png';
import knightarmorboots from '../assets/images/shoes_knightarmorboots.png';
import koreanhanbok from '../assets/images/shoes_koreanhanbok.png';
import mummy from '../assets/images/shoes_mummy.png';
import mutantfeet from '../assets/images/shoes_mutantfeet.png';
import rabbitfeet from '../assets/images/shoes_rabbitfeet.png';
import Santaboots from '../assets/images/shoes_Santaboots.png';
import Street from '../assets/images/shoes_Street.png';
import superman from '../assets/images/shoes_superman.png';
import witch from '../assets/images/shoes_witch#.png';

import ancientgreekcrown from '../assets/images/hair_ancientgreekcrown.png';
import angelring from '../assets/images/hair_angelring.png';
import balletheadpiece from '../assets/images/hair_balletheadpiece.png';
import basic_hair from '../assets/images/hair_basic.png';
import beret from '../assets/images/hair_beret.png';
import chefhat from '../assets/images/hair_chefhat.png';
import clownhat from '../assets/images/hair_clownhat.png';
import crown from '../assets/images/hair_crown.png';
import devil from '../assets/images/hair_devil.png';
import elvispresleyhair from '../assets/images/hair_elvispresleyhair.png';
import flowercrown from '../assets/images/hair_flowercrown.png';
import gradhat from '../assets/images/hair_gradhat.png';
import mutant from '../assets/images/hair_mutant.png';
import octopus from '../assets/images/hair_octopus.png';
import rabbitear from '../assets/images/hair_rabbitear.png';
import rainbowwig from '../assets/images/hair_rainbowwig.png';
import santahat from '../assets/images/hair_santahat.png';
import tophat from '../assets/images/hair_tophat.png';
import toque from '../assets/images/hair_toque.png';
import whitecap from '../assets/images/hair_whitecap.png';
import wildhair from '../assets/images/hair_wildhair.png';
import witchhat from '../assets/images/hair_witchhat.png';

import ancientgreekdress from '../assets/images/body_ancientgreekdress.png';
import apron from '../assets/images/body_apron.png';
import balletdress from '../assets/images/body_balletdress.png';
import basic_body from '../assets/images/body_basic.png';
import basictshirt from '../assets/images/body_basictshirt.png';
import clowncostume from '../assets/images/body_clowncostume.png';
import comfyjacket from '../assets/images/body_comfyjacket.png';
import doctorsgown from '../assets/images/body_doctorsgown.png';
import egyptianmummy from '../assets/images/body_egyptianmummy.png';
import hanbokdress from '../assets/images/body_hanbokdress.png';
import hawaiianbikini from '../assets/images/body_hawaiianbikini.png';
import hoodietop from '../assets/images/body_hoodietop.png';
import knightsarmor from '../assets/images/body_knightsarmor.png';
import lifesaverjacket from '../assets/images/body_lifesaverjacket.png';
import mutant_body from '../assets/images/body_mutant.png';
import manssuit from '../assets/images/body_manssuit.png';
import rabbitcostume from '../assets/images/body_rabbitcostume.png';
import rockstarcostume from '../assets/images/body_rockstarcostume.png';
import santacostume from '../assets/images/body_santacostume.png';
import streetlook from '../assets/images/body_streetlook.png';
import superman_body from '../assets/images/body_superman.png';
import swimmingsuit from '../assets/images/body_swimmingsuit.png';
import trenchcoat from '../assets/images/body_trenchcoat.png';
import turtleneck from '../assets/images/body_turtleneck.png';
import witchdress from '../assets/images/body_witchdress.png';

import face_1_1 from '../assets/images/face_1_1.png';
import face_1_2 from '../assets/images/face_1_2.png';
import face_1_3 from '../assets/images/face_1_3.png';
import face_1_4 from '../assets/images/face_1_4.png';
import face_1_5 from '../assets/images/face_1_5.png';
import face_1_6 from '../assets/images/face_1_6.png';
import face_1_7 from '../assets/images/face_1_7.png';
import face_1_8 from '../assets/images/face_1_8.png';
import face_1_9 from '../assets/images/face_1_9.png';
import face_1_10 from '../assets/images/face_1_10.png';
import face_1_11 from '../assets/images/face_1_11.png';
import face_1_12 from '../assets/images/face_1_12.png';
import face_1_13 from '../assets/images/face_1_13.png';
import face_1_14 from '../assets/images/face_1_14.png';
import face_1_15 from '../assets/images/face_1_15.png';
import face_1_16 from '../assets/images/face_1_16.png';
import face_1_17 from '../assets/images/face_1_17.png';
import face_1_18 from '../assets/images/face_1_18.png';
import face_1_19 from '../assets/images/face_1_19.png';
import face_1_20 from '../assets/images/face_1_20.png';
import face_1_21 from '../assets/images/face_1_21.png';
import face_1_22 from '../assets/images/face_1_22.png';
import face_1_23 from '../assets/images/face_1_23.png';
import face_1_24 from '../assets/images/face_1_24.png';
import face_1_25 from '../assets/images/face_1_25.png';
import face_1_26 from '../assets/images/face_1_26.png';
import face_1_27 from '../assets/images/face_1_27.png';
import face_1_28 from '../assets/images/face_1_28.png';
import face_1_29 from '../assets/images/face_1_29.png';
import face_1_30 from '../assets/images/face_1_30.png';
import face_1_31 from '../assets/images/face_1_31.png';
import face_1_32 from '../assets/images/face_1_32.png';
import face_1_33 from '../assets/images/face_1_33.png';
import face_1_34 from '../assets/images/face_1_34.png';
import face_1_35 from '../assets/images/face_1_35.png';
import face_1_36 from '../assets/images/face_1_36.png';
import face_1_37 from '../assets/images/face_1_37.png';
import face_1_38 from '../assets/images/face_1_38.png';
import face_1_39 from '../assets/images/face_1_39.png';
import face_1_40 from '../assets/images/face_1_40.png';
import face_1_41 from '../assets/images/face_1_41.png';
import face_1_42 from '../assets/images/face_1_42.png';
import face_1_43 from '../assets/images/face_1_43.png';
import face_1_44 from '../assets/images/face_1_44.png';
import face_1_45 from '../assets/images/face_1_45.png';
import face_1_46 from '../assets/images/face_1_46.png';
import face_1_47 from '../assets/images/face_1_47.png';
import face_1_48 from '../assets/images/face_1_48.png';
import face_1_49 from '../assets/images/face_1_49.png';
import face_1_50 from '../assets/images/face_1_50.png';
import face_1_51 from '../assets/images/face_1_51.png';
import face_1_52 from '../assets/images/face_1_52.png';
import face_1_53 from '../assets/images/face_1_53.png';
import face_1_54 from '../assets/images/face_1_54.png';
import face_1_55 from '../assets/images/face_1_55.png';
import face_1_56 from '../assets/images/face_1_56.png';
import face_1_57 from '../assets/images/face_1_57.png';
import face_1_58 from '../assets/images/face_1_58.png';
import face_1_59 from '../assets/images/face_1_59.png';
import face_1_60 from '../assets/images/face_1_60.png';
import face_1_61 from '../assets/images/face_1_61.png';
import face_1_62 from '../assets/images/face_1_62.png';
import face_1_63 from '../assets/images/face_1_63.png';
import face_1_64 from '../assets/images/face_1_64.png';
import face_1_65 from '../assets/images/face_1_65.png';
import face_1_66 from '../assets/images/face_1_66.png';
import face_1_67 from '../assets/images/face_1_67.png';
import face_1_68 from '../assets/images/face_1_68.png';
import face_1_69 from '../assets/images/face_1_69.png';
import face_1_70 from '../assets/images/face_1_70.png';
import face_1_71 from '../assets/images/face_1_71.png';
import face_1_72 from '../assets/images/face_1_72.png';
import face_1_73 from '../assets/images/face_1_73.png';
import face_1_74 from '../assets/images/face_1_74.png';
import face_1_75 from '../assets/images/face_1_75.png';

import face_2_1 from '../assets/images/face_2_1.png';
import face_2_2 from '../assets/images/face_2_2.png';
import face_2_3 from '../assets/images/face_2_3.png';
import face_2_4 from '../assets/images/face_2_4.png';
import face_2_5 from '../assets/images/face_2_5.png';
import face_2_6 from '../assets/images/face_2_6.png';
import face_2_7 from '../assets/images/face_2_7.png';
import face_2_8 from '../assets/images/face_2_8.png';
import face_2_9 from '../assets/images/face_2_9.png';
import face_2_10 from '../assets/images/face_2_10.png';
import face_2_11 from '../assets/images/face_2_11.png';
import face_2_12 from '../assets/images/face_2_12.png';
import face_2_13 from '../assets/images/face_2_13.png';
import face_2_14 from '../assets/images/face_2_14.png';
import face_2_15 from '../assets/images/face_2_15.png';
import face_2_16 from '../assets/images/face_2_16.png';
import face_2_17 from '../assets/images/face_2_17.png';
import face_2_18 from '../assets/images/face_2_18.png';
import face_2_19 from '../assets/images/face_2_19.png';
import face_2_20 from '../assets/images/face_2_20.png';
import face_2_21 from '../assets/images/face_2_21.png';
import face_2_22 from '../assets/images/face_2_22.png';
import face_2_23 from '../assets/images/face_2_23.png';
import face_2_24 from '../assets/images/face_2_24.png';
import face_2_25 from '../assets/images/face_2_25.png';
import face_2_26 from '../assets/images/face_2_26.png';
import face_2_27 from '../assets/images/face_2_27.png';
import face_2_28 from '../assets/images/face_2_28.png';
import face_2_29 from '../assets/images/face_2_29.png';
import face_2_30 from '../assets/images/face_2_30.png';
import face_2_31 from '../assets/images/face_2_31.png';
import face_2_32 from '../assets/images/face_2_32.png';
import face_2_33 from '../assets/images/face_2_33.png';
import face_2_34 from '../assets/images/face_2_34.png';
import face_2_35 from '../assets/images/face_2_35.png';
import face_2_36 from '../assets/images/face_2_36.png';
import face_2_37 from '../assets/images/face_2_37.png';
import face_2_38 from '../assets/images/face_2_38.png';
import face_2_39 from '../assets/images/face_2_39.png';
import face_2_40 from '../assets/images/face_2_40.png';
import face_2_41 from '../assets/images/face_2_41.png';
import face_2_42 from '../assets/images/face_2_42.png';
import face_2_43 from '../assets/images/face_2_43.png';
import face_2_44 from '../assets/images/face_2_44.png';
import face_2_45 from '../assets/images/face_2_45.png';
import face_2_46 from '../assets/images/face_2_46.png';
import face_2_47 from '../assets/images/face_2_47.png';
import face_2_48 from '../assets/images/face_2_48.png';
import face_2_49 from '../assets/images/face_2_49.png';
import face_2_50 from '../assets/images/face_2_50.png';
import face_2_51 from '../assets/images/face_2_51.png';
import face_2_52 from '../assets/images/face_2_52.png';
import face_2_53 from '../assets/images/face_2_53.png';
import face_2_54 from '../assets/images/face_2_54.png';
import face_2_55 from '../assets/images/face_2_55.png';
import face_2_56 from '../assets/images/face_2_56.png';
import face_2_57 from '../assets/images/face_2_57.png';
import face_2_58 from '../assets/images/face_2_58.png';
import face_2_59 from '../assets/images/face_2_59.png';
import face_2_60 from '../assets/images/face_2_60.png';
import face_2_61 from '../assets/images/face_2_61.png';
import face_2_62 from '../assets/images/face_2_62.png';
import face_2_63 from '../assets/images/face_2_63.png';
import face_2_64 from '../assets/images/face_2_64.png';
import face_2_65 from '../assets/images/face_2_65.png';
import face_2_66 from '../assets/images/face_2_66.png';
import face_2_67 from '../assets/images/face_2_67.png';
import face_2_68 from '../assets/images/face_2_68.png';
import face_2_69 from '../assets/images/face_2_69.png';
import face_2_70 from '../assets/images/face_2_70.png';
import face_2_71 from '../assets/images/face_2_71.png';
import face_2_72 from '../assets/images/face_2_72.png';
import face_2_73 from '../assets/images/face_2_73.png';
import face_2_74 from '../assets/images/face_2_74.png';
import face_2_75 from '../assets/images/face_2_75.png';

import face_3_1 from '../assets/images/face_3_1.png';
import face_3_2 from '../assets/images/face_3_2.png';
import face_3_3 from '../assets/images/face_3_3.png';
import face_3_4 from '../assets/images/face_3_4.png';
import face_3_5 from '../assets/images/face_3_5.png';
import face_3_6 from '../assets/images/face_3_6.png';
import face_3_7 from '../assets/images/face_3_7.png';
import face_3_8 from '../assets/images/face_3_8.png';
import face_3_9 from '../assets/images/face_3_9.png';
import face_3_10 from '../assets/images/face_3_10.png';
import face_3_11 from '../assets/images/face_3_11.png';
import face_3_12 from '../assets/images/face_3_12.png';
import face_3_13 from '../assets/images/face_3_13.png';
import face_3_14 from '../assets/images/face_3_14.png';
import face_3_15 from '../assets/images/face_3_15.png';
import face_3_16 from '../assets/images/face_3_16.png';
import face_3_17 from '../assets/images/face_3_17.png';
import face_3_18 from '../assets/images/face_3_18.png';
import face_3_19 from '../assets/images/face_3_19.png';
import face_3_20 from '../assets/images/face_3_20.png';
import face_3_21 from '../assets/images/face_3_21.png';
import face_3_22 from '../assets/images/face_3_22.png';
import face_3_23 from '../assets/images/face_3_23.png';
import face_3_24 from '../assets/images/face_3_24.png';
import face_3_25 from '../assets/images/face_3_25.png';
import face_3_26 from '../assets/images/face_3_26.png';
import face_3_27 from '../assets/images/face_3_27.png';
import face_3_28 from '../assets/images/face_3_28.png';
import face_3_29 from '../assets/images/face_3_29.png';
import face_3_30 from '../assets/images/face_3_30.png';
import face_3_31 from '../assets/images/face_3_31.png';
import face_3_32 from '../assets/images/face_3_32.png';
import face_3_33 from '../assets/images/face_3_33.png';
import face_3_34 from '../assets/images/face_3_34.png';
import face_3_35 from '../assets/images/face_3_35.png';
import face_3_36 from '../assets/images/face_3_36.png';
import face_3_37 from '../assets/images/face_3_37.png';
import face_3_38 from '../assets/images/face_3_38.png';
import face_3_39 from '../assets/images/face_3_39.png';
import face_3_40 from '../assets/images/face_3_40.png';
import face_3_41 from '../assets/images/face_3_41.png';
import face_3_42 from '../assets/images/face_3_42.png';
import face_3_43 from '../assets/images/face_3_43.png';
import face_3_44 from '../assets/images/face_3_44.png';
import face_3_45 from '../assets/images/face_3_45.png';
import face_3_46 from '../assets/images/face_3_46.png';
import face_3_47 from '../assets/images/face_3_47.png';
import face_4 from '../assets/images/face_4.png';
import basic from '../assets/images/accessory_basic.png';
import beachball from '../assets/images/accessory_beachball.png';
import beer from '../assets/images/accessory_beer.png';
import bone from '../assets/images/accessory_bone.png';
import cigarette from '../assets/images/accessory_cigarette.png';
import cottoncandy from '../assets/images/accessory_cottoncandy.png';
import cuestick from '../assets/images/accessory_cuestick.png';
import donut from '../assets/images/accessory_donut.png';
import guitar from '../assets/images/accessory_guitar.png';
import gun from '../assets/images/accessory_gun.png';
import icecream from '../assets/images/accessory_icecream.png';
import knife from '../assets/images/accessory_knife.png';
import plantpot from '../assets/images/accessory_plantpot.png';
import skull from '../assets/images/accessory_skull.png';
import slottedturner from '../assets/images/accessory_slottedturner.png';
import trident from '../assets/images/accessory_trident.png';
import vaccineshot from '../assets/images/accessory_vaccineshot.png';
import wand from '../assets/images/accessory_wand.png';
import wok from '../assets/images/accessory_wok.png';

export const attrLists = [
  {
    title: 'Background',
    children: [
      {
        iClassName: 'purple',
        iText: 'Common',
        spanText: 'Blue',
        emText: '28.8%',
        img: back_1,
      },
      {
        iClassName: 'purple',
        iText: 'Common',
        spanText: 'Red',
        emText: '27.6%',
        img: back_2,
      },
      { iClassName: 'lemon', iText: 'Uncommon', spanText: 'Black', emText: '26.3%', img: back_3 },
      { iClassName: 'mint', iText: 'Rare', spanText: 'Silver', emText: '12.2%', img: back_4 },
      { iClassName: 'neon', iText: 'Legendary', spanText: 'Gold', emText: '5.1%', img: back_5 },
    ],
  },
  // {
  //   title: 'Frame',
  //   children: [
  //     { iClassName: 'purple', iText: 'Common', spanText: 'Basic', emText: '80%' },
  //     { iClassName: 'lemon', iText: 'Uncommon', spanText: 'Gold', emText: '10%' },
  //     { iClassName: 'mint', iText: 'Rare', spanText: 'Diamond', emText: '6%' },
  //     { iClassName: 'rainbow', iText: 'Unique', spanText: 'Neon', emText: '4%' },
  //   ],
  // },

  {
    title: 'Body',
    children: [
      { iClassName: 'purple', iText: 'Common', spanText: 'Sedan', emText: '45%', img: body_1 },
      { iClassName: 'lemon', iText: 'Uncommon', spanText: 'EV Car', emText: '35%', img: body_2 },
      { iClassName: 'mint', iText: 'Rare', spanText: 'SUV', emText: '10%', img: body_3 },
      { iClassName: 'red', iText: 'Super Rare', spanText: 'Super Car', emText: '6%', img: body_4 },
      { iClassName: 'rainbow', iText: 'Unique', spanText: 'F1', emText: '3%', img: body_5 },
      { iClassName: 'neon', iText: 'Legendary', spanText: 'Future Car', emText: '1%', img: body_6 },
    ],
  },

  {
    title: 'Color',
    children: [
      { iClassName: 'purple', iText: 'Common', spanText: 'White', emText: '16.3%', img: color_1 },
      { iClassName: 'purple', iText: 'Common', spanText: 'Black', emText: '16.2%', img: color_2 },
      { iClassName: 'mint', iText: 'Rare', spanText: 'Red', emText: '11.7%', img: color_3 },
      { iClassName: 'mint', iText: 'Rare', spanText: 'Yellow', emText: '11.4%', img: color_4 },
      { iClassName: 'mint', iText: 'Rare', spanText: 'Green', emText: '12.4%', img: color_5 },
      { iClassName: 'mint', iText: 'Rare', spanText: 'Blue', emText: '12%', img: color_6 },
      { iClassName: 'red', iText: 'Super Rare', spanText: 'Purple', emText: '7.5%', img: color_7 },
      { iClassName: 'red', iText: 'Super Rare', spanText: 'Rainbow', emText: '6.7%', img: color_8 },
      { iClassName: 'rainbow', iText: 'Unique', spanText: 'Camo', emText: '4.8%', img: color_9 },
      {
        iClassName: 'neon',
        iText: 'Legendary',
        spanText: 'Crystal',
        emText: '1%',
        img: color_10,
      },
    ],
  },
  {
    title: 'Hood',
    children: [
      { iClassName: 'purple', iText: 'Common', spanText: 'None', emText: '51.6%', img: hood_0 },
      { iClassName: 'mint', iText: 'Rare', spanText: 'Balloon', emText: '24.5%', img: hood_1 },
      {
        iClassName: 'red',
        iText: 'Super Rare',
        spanText: 'Machine Gun',
        emText: '12.4%',
        img: hood_2,
      },
      { iClassName: 'rainbow', iText: 'Unique', spanText: 'Rocket', emText: '9.7%', img: hood_3 },
      { iClassName: 'neon', iText: 'Legendary', spanText: 'Shiba', emText: '1.8%', img: hood_4 },
    ],
  },

  {
    title: 'Wheel',
    children: [
      { iClassName: 'purple', iText: 'Common', spanText: 'Normal_1', emText: '27%', img: wheel_1 },
      {
        iClassName: 'lemon',
        iText: 'Uncommon',
        spanText: 'Normal_2',
        emText: '22.9%',
        img: wheel_2,
      },
      { iClassName: 'mint', iText: 'Rare', spanText: 'Sports', emText: '21.7%', img: wheel_3 },
      {
        iClassName: 'red',
        iText: 'Super Rare',
        spanText: 'Diamond',
        emText: '16.8%',
        img: wheel_4,
      },
      { iClassName: 'rainbow', iText: 'Unique', spanText: 'Neon', emText: '11.6%', img: wheel_5 },
    ],
  },
];

let accImages = [
  { img: basic, name: 'basic' },
  { img: beachball, name: 'beachball' },
  { img: beer, name: 'beer' },
  { img: bone, name: 'bone' },
  { img: cigarette, name: 'cigarette' },
  { img: cottoncandy, name: 'cottoncandy' },
  { img: cuestick, name: 'cuestick' },
  { img: donut, name: 'donut' },
  { img: guitar, name: 'guitar' },
  { img: gun, name: 'gun' },
  { img: icecream, name: 'icecream' },
  { img: knife, name: 'knife' },
  { img: plantpot, name: 'plantpot' },
  { img: skull, name: 'skull' },
  { img: slottedturner, name: 'slottedturner' },
  { img: trident, name: 'trident' },
  { img: vaccineshot, name: 'vaccineshot' },
  { img: wand, name: 'wand' },
  { img: wok, name: 'wok' },
];
const bgImages = [
  { img: gloomycastle, name: 'gloomycastle' },
  { img: gradient1, name: 'gradient1' },
  { img: gradient2, name: 'gradient2' },
  { img: gradient3, name: 'gradient3' },
  { img: gradient4, name: 'gradient4' },
  { img: gradient5, name: 'gradient5' },
  { img: gradient6, name: 'gradient6' },
  { img: graffittistreetart, name: 'graffittistreetart' },
  { img: greekbeach, name: 'greekbeach' },
  { img: mooninnightsky, name: 'mooninnightsky' },
  { img: solid1, name: 'solid1' },
  { img: solid2, name: 'solid2' },
  { img: solid3, name: 'solid3' },
  { img: solid4, name: 'solid4' },
  { img: solid5, name: 'solid5' },
  { img: solid6, name: 'solid6' },
  { img: solid7, name: 'solid7' },
  { img: solid8, name: 'solid8' },
  { img: solid9, name: 'solid9' },
  { img: solid10, name: 'solid10' },
];

const shoesImages = [
  { img: line, name: 'line' },
  { img: balletdance, name: 'balletdance' },
  { img: basic_shoes, name: 'basic_shoes' },
  { img: blueconverse, name: 'blueconverse' },
  { img: clown, name: 'clown' },
  { img: fancylaceboots, name: 'fancylaceboots' },
  { img: formalleather, name: 'formalleather' },
  { img: hawaiianleaf, name: 'hawaiianleaf' },
  { img: knightarmorboots, name: 'knightarmorboots' },
  { img: knightarmorboots, name: 'knightarmorboots' },
  { img: koreanhanbok, name: 'koreanhanbok' },
  { img: mummy, name: 'mummy' },
  { img: mutantfeet, name: 'mutantfeet' },
  { img: rabbitfeet, name: 'rabbitfeet' },
  { img: Santaboots, name: 'Santaboots' },
  { img: Street, name: 'Street' },
  { img: basicBoots, name: 'basicBoots' },

  { img: superman, name: 'superman' },
  { img: witch, name: 'witch' },
];

const bodyImages = [
  { img: ancientgreekdress, name: 'ancientgreekdress' },
  { img: apron, name: 'apron' },
  { img: balletdress, name: 'balletdress' },
  { img: basic_body, name: 'basic_body' },
  { img: basictshirt, name: 'basictshirt' },
  { img: clowncostume, name: 'clowncostume' },
  { img: comfyjacket, name: 'comfyjacket' },
  { img: doctorsgown, name: 'doctorsgown' },
  { img: egyptianmummy, name: 'egyptianmummy' },
  { img: hanbokdress, name: 'hanbokdress' },
  { img: hawaiianbikini, name: 'hawaiianbikini' },
  { img: hoodietop, name: 'hoodietop' },
  { img: knightsarmor, name: 'knightsarmor' },
  { img: lifesaverjacket, name: 'lifesaverjacket' },
  { img: manssuit, name: 'manssuit' },
  { img: rabbitcostume, name: 'rabbitcostume' },
  { img: rockstarcostume, name: 'rockstarcostume' },
  { img: santacostume, name: 'santacostume' },
  { img: streetlook, name: 'streetlook' },
  { img: superman_body, name: 'superman_body' },
  { img: swimmingsuit, name: 'swimmingsuit' },
  { img: trenchcoat, name: 'trenchcoat' },
  { img: turtleneck, name: 'turtleneck' },
  { img: witchdress, name: 'witchdress' },
];

const hairImages = [
  { img: ancientgreekcrown, name: 'ancientgreekcrown' },
  { img: angelring, name: 'angelring' },
  { img: balletheadpiece, name: 'balletheadpiece' },
  { img: basic_hair, name: 'basic_hair' },
  { img: beret, name: 'beret' },
  { img: chefhat, name: 'chefhat' },
  { img: clownhat, name: 'clownhat' },
  { img: crown, name: 'crown' },
  { img: devil, name: 'devil' },
  { img: elvispresleyhair, name: 'elvispresleyhair' },
  { img: flowercrown, name: 'flowercrown' },
  { img: gradhat, name: 'gradhat' },
  { img: mutant, name: 'mutant' },
  { img: octopus, name: 'octopus' },
  { img: rabbitear, name: 'rabbitear' },
  { img: rainbowwig, name: 'rainbowwig' },
  { img: santahat, name: 'santahat' },
  { img: tophat, name: 'tophat' },
  { img: toque, name: 'toque' },
  { img: whitecap, name: 'whitecap' },
  { img: wildhair, name: 'wildhair' },
  { img: witchhat, name: 'witchhat' },
];

interface IAcc {
  spanText: string;
  iText: 'Common' | 'Uncommon' | 'Super Rare' | 'Unique' | 'Legendary' | 'Rare';
  emText: string;
  img?: string;
  iClassName?: string;
}

const faceImages = [
  { img: face_1_1, name: 'face_1_1' },
  { img: face_1_2, name: 'face_1_2' },
  { img: face_1_3, name: 'face_1_3' },
  { img: face_1_4, name: 'face_1_4' },
  { img: face_1_5, name: 'face_1_5' },
  { img: face_1_6, name: 'face_1_6' },
  { img: face_1_7, name: 'face_1_7' },
  { img: face_1_8, name: 'face_1_8' },
  { img: face_1_9, name: 'face_1_9' },
  { img: face_1_10, name: 'face_1_10' },
  { img: face_1_11, name: 'face_1_11' },
  { img: face_1_12, name: 'face_1_12' },
  { img: face_1_13, name: 'face_1_13' },
  { img: face_1_14, name: 'face_1_14' },
  { img: face_1_15, name: 'face_1_15' },
  { img: face_1_16, name: 'face_1_16' },
  { img: face_1_17, name: 'face_1_17' },
  { img: face_1_18, name: 'face_1_18' },
  { img: face_1_19, name: 'face_1_19' },
  { img: face_1_20, name: 'face_1_20' },
  { img: face_1_21, name: 'face_1_21' },
  { img: face_1_22, name: 'face_1_22' },
  { img: face_1_23, name: 'face_1_23' },
  { img: face_1_24, name: 'face_1_24' },
  { img: face_1_25, name: 'face_1_25' },
  { img: face_1_26, name: 'face_1_26' },
  { img: face_1_27, name: 'face_1_27' },
  { img: face_1_28, name: 'face_1_28' },
  { img: face_1_29, name: 'face_1_29' },
  { img: face_1_30, name: 'face_1_30' },
  { img: face_1_31, name: 'face_1_31' },
  { img: face_1_32, name: 'face_1_32' },
  { img: face_1_33, name: 'face_1_33' },
  { img: face_1_34, name: 'face_1_34' },
  { img: face_1_35, name: 'face_1_35' },
  { img: face_1_36, name: 'face_1_36' },
  { img: face_1_37, name: 'face_1_37' },
  { img: face_1_38, name: 'face_1_38' },
  { img: face_1_39, name: 'face_1_39' },
  { img: face_1_40, name: 'face_1_40' },
  { img: face_1_41, name: 'face_1_41' },
  { img: face_1_42, name: 'face_1_42' },
  { img: face_1_43, name: 'face_1_43' },
  { img: face_1_44, name: 'face_1_44' },
  { img: face_1_45, name: 'face_1_45' },
  { img: face_1_46, name: 'face_1_46' },
  { img: face_1_47, name: 'face_1_47' },
  { img: face_1_48, name: 'face_1_48' },
  { img: face_1_49, name: 'face_1_49' },
  { img: face_1_50, name: 'face_1_50' },
  { img: face_1_51, name: 'face_1_51' },
  { img: face_1_52, name: 'face_1_52' },
  { img: face_1_53, name: 'face_1_53' },
  { img: face_1_54, name: 'face_1_54' },
  { img: face_1_55, name: 'face_1_55' },
  { img: face_1_56, name: 'face_1_56' },
  { img: face_1_57, name: 'face_1_57' },
  { img: face_1_58, name: 'face_1_58' },
  { img: face_1_59, name: 'face_1_59' },
  { img: face_1_60, name: 'face_1_60' },
  { img: face_1_61, name: 'face_1_61' },
  { img: face_1_62, name: 'face_1_62' },
  { img: face_1_63, name: 'face_1_63' },
  { img: face_1_64, name: 'face_1_64' },
  { img: face_1_65, name: 'face_1_65' },
  { img: face_1_66, name: 'face_1_66' },
  { img: face_1_67, name: 'face_1_67' },
  { img: face_1_68, name: 'face_1_68' },
  { img: face_1_69, name: 'face_1_69' },
  { img: face_1_70, name: 'face_1_70' },
  { img: face_1_71, name: 'face_1_71' },
  { img: face_1_72, name: 'face_1_72' },
  { img: face_1_73, name: 'face_1_73' },
  { img: face_1_74, name: 'face_1_74' },
  { img: face_1_75, name: 'face_1_75' },
  { img: face_2_1, name: 'face_2_1' },
  { img: face_2_2, name: 'face_2_2' },
  { img: face_2_3, name: 'face_2_3' },
  { img: face_2_4, name: 'face_2_4' },
  { img: face_2_5, name: 'face_2_5' },
  { img: face_2_6, name: 'face_2_6' },
  { img: face_2_7, name: 'face_2_7' },
  { img: face_2_8, name: 'face_2_8' },
  { img: face_2_9, name: 'face_2_9' },
  { img: face_2_10, name: 'face_2_10' },
  { img: face_2_11, name: 'face_2_11' },
  { img: face_2_12, name: 'face_2_12' },
  { img: face_2_13, name: 'face_2_13' },
  { img: face_2_14, name: 'face_2_14' },
  { img: face_2_15, name: 'face_2_15' },
  { img: face_2_16, name: 'face_2_16' },
  { img: face_2_17, name: 'face_2_17' },
  { img: face_2_18, name: 'face_2_18' },
  { img: face_2_19, name: 'face_2_19' },
  { img: face_2_20, name: 'face_2_20' },
  { img: face_2_21, name: 'face_2_21' },
  { img: face_2_22, name: 'face_2_22' },
  { img: face_2_23, name: 'face_2_23' },
  { img: face_2_24, name: 'face_2_24' },
  { img: face_2_25, name: 'face_2_25' },
  { img: face_2_26, name: 'face_2_26' },
  { img: face_2_27, name: 'face_2_27' },
  { img: face_2_28, name: 'face_2_28' },
  { img: face_2_29, name: 'face_2_29' },
  { img: face_2_30, name: 'face_2_30' },
  { img: face_2_31, name: 'face_2_31' },
  { img: face_2_32, name: 'face_2_32' },
  { img: face_2_33, name: 'face_2_33' },
  { img: face_2_34, name: 'face_2_34' },
  { img: face_2_35, name: 'face_2_35' },
  { img: face_2_36, name: 'face_2_36' },
  { img: face_2_37, name: 'face_2_37' },
  { img: face_2_38, name: 'face_2_38' },
  { img: face_2_39, name: 'face_2_39' },
  { img: face_2_40, name: 'face_2_40' },
  { img: face_2_41, name: 'face_2_41' },
  { img: face_2_42, name: 'face_2_42' },
  { img: face_2_43, name: 'face_2_43' },
  { img: face_2_44, name: 'face_2_44' },
  { img: face_2_45, name: 'face_2_45' },
  { img: face_2_46, name: 'face_2_46' },
  { img: face_2_47, name: 'face_2_47' },
  { img: face_2_48, name: 'face_2_48' },
  { img: face_2_49, name: 'face_2_49' },
  { img: face_2_50, name: 'face_2_50' },
  { img: face_2_51, name: 'face_2_51' },
  { img: face_2_52, name: 'face_2_52' },
  { img: face_2_53, name: 'face_2_53' },
  { img: face_2_54, name: 'face_2_54' },
  { img: face_2_55, name: 'face_2_55' },
  { img: face_2_56, name: 'face_2_56' },
  { img: face_2_57, name: 'face_2_57' },
  { img: face_2_58, name: 'face_2_58' },
  { img: face_2_59, name: 'face_2_59' },
  { img: face_2_60, name: 'face_2_60' },
  { img: face_2_61, name: 'face_2_61' },
  { img: face_2_62, name: 'face_2_62' },
  { img: face_2_63, name: 'face_2_63' },
  { img: face_2_64, name: 'face_2_64' },
  { img: face_2_65, name: 'face_2_65' },
  { img: face_2_66, name: 'face_2_66' },
  { img: face_2_67, name: 'face_2_67' },
  { img: face_2_68, name: 'face_2_68' },
  { img: face_2_69, name: 'face_2_69' },
  { img: face_2_70, name: 'face_2_70' },
  { img: face_2_71, name: 'face_2_71' },
  { img: face_2_72, name: 'face_2_72' },
  { img: face_2_73, name: 'face_2_73' },
  { img: face_2_74, name: 'face_2_74' },
  { img: face_2_75, name: 'face_2_75' },

  { img: face_3_1, name: 'face_3_1' },
  { img: face_3_2, name: 'face_3_2' },
  { img: face_3_3, name: 'face_3_3' },
  { img: face_3_4, name: 'face_3_4' },
  { img: face_3_5, name: 'face_3_5' },
  { img: face_3_6, name: 'face_3_6' },
  { img: face_3_7, name: 'face_3_7' },
  { img: face_3_8, name: 'face_3_8' },
  { img: face_3_9, name: 'face_3_9' },
  { img: face_3_10, name: 'face_3_10' },
  { img: face_3_11, name: 'face_3_11' },
  { img: face_3_12, name: 'face_3_12' },
  { img: face_3_13, name: 'face_3_13' },
  { img: face_3_14, name: 'face_3_14' },
  { img: face_3_15, name: 'face_3_15' },
  { img: face_3_16, name: 'face_3_16' },
  { img: face_3_17, name: 'face_3_17' },
  { img: face_3_18, name: 'face_3_18' },
  { img: face_3_19, name: 'face_3_19' },
  { img: face_3_20, name: 'face_3_20' },
  { img: face_3_21, name: 'face_3_21' },
  { img: face_3_22, name: 'face_3_22' },
  { img: face_3_23, name: 'face_3_23' },
  { img: face_3_24, name: 'face_3_24' },
  { img: face_3_25, name: 'face_3_25' },
  { img: face_3_26, name: 'face_3_26' },
  { img: face_3_27, name: 'face_3_27' },
  { img: face_3_28, name: 'face_3_28' },
  { img: face_3_29, name: 'face_3_29' },
  { img: face_3_30, name: 'face_3_30' },
  { img: face_3_31, name: 'face_3_31' },
  { img: face_3_32, name: 'face_3_32' },
  { img: face_3_33, name: 'face_3_33' },
  { img: face_3_34, name: 'face_3_34' },
  { img: face_3_35, name: 'face_3_35' },
  { img: face_3_36, name: 'face_3_36' },
  { img: face_3_37, name: 'face_3_37' },
  { img: face_3_38, name: 'face_3_38' },
  { img: face_3_39, name: 'face_3_39' },
  { img: face_3_40, name: 'face_3_40' },
  { img: face_3_41, name: 'face_3_41' },
  { img: face_3_42, name: 'face_3_42' },
  { img: face_3_43, name: 'face_3_43' },
  { img: face_3_44, name: 'face_3_44' },
  { img: face_3_45, name: 'face_3_45' },
  { img: face_3_46, name: 'face_3_46' },
  { img: face_3_47, name: 'face_3_47' },
  { img: face_4, name: 'face_4' },
];

let faceArray: IAcc[] = [
  { spanText: 'face_1_1', iText: 'Super Rare', emText: '0.20%' },
  { spanText: 'face_1_2', iText: 'Super Rare', emText: '0.10%' },
  { spanText: 'face_1_3', iText: 'Super Rare', emText: '0.50%' },
  { spanText: 'face_1_4', iText: 'Super Rare', emText: '0.20%' },
  { spanText: 'face_1_5', iText: 'Super Rare', emText: '0.20%' },
  { spanText: 'face_1_6', iText: 'Super Rare', emText: '0.30%' },
  { spanText: 'face_1_7', iText: 'Super Rare', emText: '0.30%' },
  { spanText: 'face_1_8', iText: 'Super Rare', emText: '0.20%' },
  { spanText: 'face_1_9', iText: 'Super Rare', emText: '0.10%' },
  { spanText: 'face_1_10', iText: 'Super Rare', emText: '0.30%' },
  { spanText: 'face_1_11', iText: 'Super Rare', emText: '0.30%' },
  { spanText: 'face_1_12', iText: 'Super Rare', emText: '0.10%' },
  { spanText: 'face_1_13', iText: 'Super Rare', emText: '0.10%' },
  { spanText: 'face_1_14', iText: 'Super Rare', emText: '0.10%' },
  { spanText: 'face_1_15', iText: 'Super Rare', emText: '0.40%' },
  { spanText: 'face_1_16', iText: 'Super Rare', emText: '0.10%' },
  { spanText: 'face_1_17', iText: 'Super Rare', emText: '0.10%' },
  { spanText: 'face_1_18', iText: 'Super Rare', emText: '0.10%' },
  { spanText: 'face_1_19', iText: 'Super Rare', emText: '0.10%' },
  { spanText: 'face_1_20', iText: 'Super Rare', emText: '0.20%' },
  { spanText: 'face_1_21', iText: 'Super Rare', emText: '0.50%' },
  { spanText: 'face_1_22', iText: 'Super Rare', emText: '0.40%' },
  { spanText: 'face_1_23', iText: 'Super Rare', emText: '0.10%' },
  { spanText: 'face_1_24', iText: 'Super Rare', emText: '0.30%' },
  { spanText: 'face_1_25', iText: 'Super Rare', emText: '0.70%' },
  { spanText: 'face_1_26', iText: 'Super Rare', emText: '0.00%' },
  { spanText: 'face_1_27', iText: 'Super Rare', emText: '0.20%' },
  { spanText: 'face_1_28', iText: 'Super Rare', emText: '0.20%' },
  { spanText: 'face_1_29', iText: 'Super Rare', emText: '0.30%' },
  { spanText: 'face_1_30', iText: 'Super Rare', emText: '0.20%' },
  { spanText: 'face_1_31', iText: 'Super Rare', emText: '0.20%' },
  { spanText: 'face_1_32', iText: 'Super Rare', emText: '0.30%' },
  { spanText: 'face_1_33', iText: 'Super Rare', emText: '0.40%' },
  { spanText: 'face_1_34', iText: 'Super Rare', emText: '0.00%' },
  { spanText: 'face_1_35', iText: 'Super Rare', emText: '0.10%' },
  { spanText: 'face_1_36', iText: 'Super Rare', emText: '0.30%' },
  { spanText: 'face_1_37', iText: 'Super Rare', emText: '0.20%' },
  { spanText: 'face_1_38', iText: 'Super Rare', emText: '0.10%' },
  { spanText: 'face_1_39', iText: 'Super Rare', emText: '0.10%' },
  { spanText: 'face_1_40', iText: 'Super Rare', emText: '0.20%' },
  { spanText: 'face_1_41', iText: 'Super Rare', emText: '0.20%' },
  { spanText: 'face_1_42', iText: 'Super Rare', emText: '0.40%' },
  { spanText: 'face_1_43', iText: 'Super Rare', emText: '0.10%' },
  { spanText: 'face_1_44', iText: 'Super Rare', emText: '0.00%' },
  { spanText: 'face_1_45', iText: 'Super Rare', emText: '0.00%' },
  { spanText: 'face_1_46', iText: 'Super Rare', emText: '0.20%' },
  { spanText: 'face_1_47', iText: 'Super Rare', emText: '0.10%' },
  { spanText: 'face_1_48', iText: 'Super Rare', emText: '0.20%' },
  { spanText: 'face_1_49', iText: 'Super Rare', emText: '0.30%' },
  { spanText: 'face_1_50', iText: 'Super Rare', emText: '0.30%' },
  { spanText: 'face_1_51', iText: 'Super Rare', emText: '0.10%' },
  { spanText: 'face_1_52', iText: 'Super Rare', emText: '0.60%' },
  { spanText: 'face_1_53', iText: 'Super Rare', emText: '0.10%' },
  { spanText: 'face_1_54', iText: 'Super Rare', emText: '0.10%' },
  { spanText: 'face_1_55', iText: 'Super Rare', emText: '0.30%' },
  { spanText: 'face_1_56', iText: 'Super Rare', emText: '0.10%' },
  { spanText: 'face_1_57', iText: 'Super Rare', emText: '0.00%' },
  { spanText: 'face_1_58', iText: 'Super Rare', emText: '0.20%' },
  { spanText: 'face_1_59', iText: 'Super Rare', emText: '0.30%' },
  { spanText: 'face_1_60', iText: 'Super Rare', emText: '0.00%' },
  { spanText: 'face_1_61', iText: 'Super Rare', emText: '0.10%' },
  { spanText: 'face_1_62', iText: 'Super Rare', emText: '0.20%' },
  { spanText: 'face_1_63', iText: 'Super Rare', emText: '0.30%' },
  { spanText: 'face_1_64', iText: 'Super Rare', emText: '0.20%' },
  { spanText: 'face_1_65', iText: 'Super Rare', emText: '0.00%' },
  { spanText: 'face_1_66', iText: 'Super Rare', emText: '0.20%' },
  { spanText: 'face_1_67', iText: 'Super Rare', emText: '0.20%' },
  { spanText: 'face_1_68', iText: 'Super Rare', emText: '0.40%' },
  { spanText: 'face_1_69', iText: 'Super Rare', emText: '0.10%' },
  { spanText: 'face_1_70', iText: 'Super Rare', emText: '0.30%' },
  { spanText: 'face_1_71', iText: 'Super Rare', emText: '0.40%' },
  { spanText: 'face_1_72', iText: 'Super Rare', emText: '0.50%' },
  { spanText: 'face_1_73', iText: 'Super Rare', emText: '0.40%' },
  { spanText: 'face_1_74', iText: 'Super Rare', emText: '0.20%' },
  { spanText: 'face_1_75', iText: 'Super Rare', emText: '0.40%' },
  { spanText: 'face_2_1', iText: 'Rare', emText: '0.20%' },
  { spanText: 'face_2_2', iText: 'Rare', emText: '0.60%' },
  { spanText: 'face_2_3', iText: 'Rare', emText: '0.50%' },
  { spanText: 'face_2_4', iText: 'Rare', emText: '0.80%' },
  { spanText: 'face_2_5', iText: 'Rare', emText: '0.10%' },
  { spanText: 'face_2_6', iText: 'Rare', emText: '0.40%' },
  { spanText: 'face_2_7', iText: 'Rare', emText: '0.30%' },
  { spanText: 'face_2_8', iText: 'Rare', emText: '0.10%' },
  { spanText: 'face_2_9', iText: 'Rare', emText: '0.50%' },
  { spanText: 'face_2_10', iText: 'Rare', emText: '0.30%' },
  { spanText: 'face_2_11', iText: 'Rare', emText: '0.30%' },
  { spanText: 'face_2_12', iText: 'Rare', emText: '0.50%' },
  { spanText: 'face_2_13', iText: 'Rare', emText: '0.50%' },
  { spanText: 'face_2_14', iText: 'Rare', emText: '0.50%' },
  { spanText: 'face_2_15', iText: 'Rare', emText: '0.30%' },
  { spanText: 'face_2_16', iText: 'Rare', emText: '0.40%' },
  { spanText: 'face_2_17', iText: 'Rare', emText: '0.30%' },
  { spanText: 'face_2_18', iText: 'Rare', emText: '0.30%' },
  { spanText: 'face_2_19', iText: 'Rare', emText: '0.30%' },
  { spanText: 'face_2_20', iText: 'Rare', emText: '0.60%' },
  { spanText: 'face_2_21', iText: 'Rare', emText: '0.30%' },
  { spanText: 'face_2_22', iText: 'Rare', emText: '0.20%' },
  { spanText: 'face_2_23', iText: 'Rare', emText: '0.50%' },
  { spanText: 'face_2_24', iText: 'Rare', emText: '0.40%' },
  { spanText: 'face_2_25', iText: 'Rare', emText: '0.30%' },
  { spanText: 'face_2_26', iText: 'Rare', emText: '0.30%' },
  { spanText: 'face_2_27', iText: 'Rare', emText: '0.90%' },
  { spanText: 'face_2_28', iText: 'Rare', emText: '0.60%' },
  { spanText: 'face_2_29', iText: 'Rare', emText: '0.40%' },
  { spanText: 'face_2_30', iText: 'Rare', emText: '0.20%' },
  { spanText: 'face_2_31', iText: 'Rare', emText: '0.50%' },
  { spanText: 'face_2_32', iText: 'Rare', emText: '0.40%' },
  { spanText: 'face_2_33', iText: 'Rare', emText: '0.40%' },
  { spanText: 'face_2_34', iText: 'Rare', emText: '0.50%' },
  { spanText: 'face_2_35', iText: 'Rare', emText: '0.30%' },
  { spanText: 'face_2_36', iText: 'Rare', emText: '0.40%' },
  { spanText: 'face_2_37', iText: 'Rare', emText: '0.30%' },
  { spanText: 'face_2_38', iText: 'Rare', emText: '0.50%' },
  { spanText: 'face_2_39', iText: 'Rare', emText: '0.30%' },
  { spanText: 'face_2_40', iText: 'Rare', emText: '0.90%' },
  { spanText: 'face_2_41', iText: 'Rare', emText: '0.20%' },
  { spanText: 'face_2_42', iText: 'Rare', emText: '0.80%' },
  { spanText: 'face_2_43', iText: 'Rare', emText: '0.10%' },
  { spanText: 'face_2_44', iText: 'Rare', emText: '0.50%' },
  { spanText: 'face_2_45', iText: 'Rare', emText: '0.30%' },
  { spanText: 'face_2_46', iText: 'Rare', emText: '0.70%' },
  { spanText: 'face_2_47', iText: 'Rare', emText: '0.20%' },
  { spanText: 'face_2_48', iText: 'Rare', emText: '0.50%' },
  { spanText: 'face_2_49', iText: 'Rare', emText: '0.20%' },
  { spanText: 'face_2_50', iText: 'Rare', emText: '0.40%' },
  { spanText: 'face_2_51', iText: 'Rare', emText: '0.40%' },
  { spanText: 'face_2_52', iText: 'Rare', emText: '0.50%' },
  { spanText: 'face_2_53', iText: 'Rare', emText: '0.00%' },
  { spanText: 'face_2_54', iText: 'Rare', emText: '1.00%' },
  { spanText: 'face_2_55', iText: 'Rare', emText: '0.40%' },
  { spanText: 'face_2_56', iText: 'Rare', emText: '1.20%' },
  { spanText: 'face_2_57', iText: 'Rare', emText: '0.20%' },
  { spanText: 'face_2_58', iText: 'Rare', emText: '0.50%' },
  { spanText: 'face_2_59', iText: 'Rare', emText: '0.30%' },
  { spanText: 'face_2_60', iText: 'Rare', emText: '0.30%' },
  { spanText: 'face_2_61', iText: 'Rare', emText: '0.40%' },
  { spanText: 'face_2_62', iText: 'Rare', emText: '0.30%' },
  { spanText: 'face_2_63', iText: 'Rare', emText: '0.20%' },
  { spanText: 'face_2_64', iText: 'Rare', emText: '0.40%' },
  { spanText: 'face_2_65', iText: 'Rare', emText: '0.20%' },
  { spanText: 'face_2_66', iText: 'Rare', emText: '0.40%' },
  { spanText: 'face_2_67', iText: 'Rare', emText: '0.50%' },
  { spanText: 'face_2_68', iText: 'Rare', emText: '0.40%' },
  { spanText: 'face_2_69', iText: 'Rare', emText: '0.50%' },
  { spanText: 'face_2_70', iText: 'Rare', emText: '0.50%' },
  { spanText: 'face_2_71', iText: 'Rare', emText: '0.30%' },
  { spanText: 'face_2_72', iText: 'Rare', emText: '0.50%' },
  { spanText: 'face_2_73', iText: 'Rare', emText: '0.50%' },
  { spanText: 'face_2_74', iText: 'Rare', emText: '0.60%' },
  { spanText: 'face_2_75', iText: 'Rare', emText: '0.30%' },
  { spanText: 'face_3_1', iText: 'Uncommon', emText: '1.00%' },
  { spanText: 'face_3_2', iText: 'Uncommon', emText: '0.60%' },
  { spanText: 'face_3_3', iText: 'Uncommon', emText: '0.80%' },
  { spanText: 'face_3_4', iText: 'Uncommon', emText: '0.90%' },
  { spanText: 'face_3_5', iText: 'Uncommon', emText: '1.30%' },
  { spanText: 'face_3_6', iText: 'Uncommon', emText: '0.60%' },
  { spanText: 'face_3_7', iText: 'Uncommon', emText: '0.70%' },
  { spanText: 'face_3_8', iText: 'Uncommon', emText: '0.90%' },
  { spanText: 'face_3_9', iText: 'Uncommon', emText: '1.40%' },
  { spanText: 'face_3_10', iText: 'Uncommon', emText: '0.80%' },
  { spanText: 'face_3_11', iText: 'Uncommon', emText: '1.60%' },
  { spanText: 'face_3_12', iText: 'Uncommon', emText: '1.50%' },
  { spanText: 'face_3_13', iText: 'Uncommon', emText: '0.60%' },
  { spanText: 'face_3_14', iText: 'Uncommon', emText: '0.80%' },
  { spanText: 'face_3_15', iText: 'Uncommon', emText: '0.80%' },
  { spanText: 'face_3_16', iText: 'Uncommon', emText: '0.60%' },
  { spanText: 'face_3_17', iText: 'Uncommon', emText: '0.90%' },
  { spanText: 'face_3_18', iText: 'Uncommon', emText: '0.90%' },
  { spanText: 'face_3_19', iText: 'Uncommon', emText: '1.00%' },
  { spanText: 'face_3_20', iText: 'Uncommon', emText: '0.70%' },
  { spanText: 'face_3_21', iText: 'Uncommon', emText: '0.90%' },
  { spanText: 'face_3_22', iText: 'Uncommon', emText: '1.00%' },
  { spanText: 'face_3_23', iText: 'Uncommon', emText: '0.70%' },
  { spanText: 'face_3_24', iText: 'Uncommon', emText: '0.70%' },
  { spanText: 'face_3_25', iText: 'Uncommon', emText: '1.10%' },
  { spanText: 'face_3_26', iText: 'Uncommon', emText: '1.10%' },
  { spanText: 'face_3_27', iText: 'Uncommon', emText: '1.20%' },
  { spanText: 'face_3_28', iText: 'Uncommon', emText: '0.90%' },
  { spanText: 'face_3_29', iText: 'Uncommon', emText: '1.30%' },
  { spanText: 'face_3_30', iText: 'Uncommon', emText: '0.60%' },
  { spanText: 'face_3_31', iText: 'Uncommon', emText: '1.30%' },
  { spanText: 'face_3_32', iText: 'Uncommon', emText: '1.60%' },
  { spanText: 'face_3_33', iText: 'Uncommon', emText: '1.10%' },
  { spanText: 'face_3_34', iText: 'Uncommon', emText: '1.50%' },
  { spanText: 'face_3_35', iText: 'Uncommon', emText: '0.60%' },
  { spanText: 'face_3_36', iText: 'Uncommon', emText: '1.00%' },
  { spanText: 'face_3_37', iText: 'Uncommon', emText: '1.40%' },
  { spanText: 'face_3_38', iText: 'Uncommon', emText: '1.00%' },
  { spanText: 'face_3_39', iText: 'Uncommon', emText: '1.00%' },
  { spanText: 'face_3_40', iText: 'Uncommon', emText: '1.00%' },
  { spanText: 'face_3_41', iText: 'Uncommon', emText: '0.80%' },
  { spanText: 'face_3_42', iText: 'Uncommon', emText: '0.80%' },
  { spanText: 'face_3_43', iText: 'Uncommon', emText: '1.80%' },
  { spanText: 'face_3_44', iText: 'Uncommon', emText: '0.80%' },
  { spanText: 'face_3_45', iText: 'Uncommon', emText: '0.80%' },
  { spanText: 'face_3_46', iText: 'Uncommon', emText: '0.50%' },
  { spanText: 'face_3_47', iText: 'Uncommon', emText: '1.40%' },
  { spanText: 'face_4', iText: 'Common', emText: '6.30%' },
];

let bgArray: IAcc[] = [
  { spanText: 'GloomyCastle', iText: 'Legendary', emText: '1.60%' },
  { spanText: 'GraffittiStreetArt', iText: 'Legendary', emText: '0.70%' },
  { spanText: 'GreekBeach', iText: 'Legendary', emText: '1.30%' },
  { spanText: 'MooninNightSky', iText: 'Legendary', emText: '0.80%' },
  { spanText: 'Gradient1', iText: 'Super Rare', emText: '2.80%' },
  { spanText: 'Gradient2', iText: 'Super Rare', emText: '3.50%' },
  { spanText: 'Gradient3', iText: 'Super Rare', emText: '2.80%' },
  { spanText: 'Gradient4', iText: 'Super Rare', emText: '2.40%' },
  { spanText: 'Gradient5', iText: 'Super Rare', emText: '2.60%' },
  { spanText: 'Gradient6', iText: 'Super Rare', emText: '2.90%' },
  { spanText: 'Solid1', iText: 'Common', emText: '8.20%' },
  { spanText: 'Solid2', iText: 'Common', emText: '7.80%' },
  { spanText: 'Solid3', iText: 'Common', emText: '7.20%' },
  { spanText: 'Solid4', iText: 'Common', emText: '5.90%' },
  { spanText: 'Solid5', iText: 'Common', emText: '9.10%' },
  { spanText: 'Solid6', iText: 'Common', emText: '6.90%' },
  { spanText: 'Solid7', iText: 'Common', emText: '7.80%' },
  { spanText: 'Solid8', iText: 'Common', emText: '8.60%' },
  { spanText: 'Solid9', iText: 'Common', emText: '8.30%' },
  { spanText: 'Solid10', iText: 'Common', emText: '8.80%' },
];

let shoesArray: IAcc[] = [
  { spanText: 'KnightArmorBoots', iText: 'Legendary', emText: '1.00%' },
  { spanText: 'RabbitFeet', iText: 'Legendary', emText: '1.30%' },
  { spanText: 'Mummy', iText: 'Legendary', emText: '1.20%' },
  { spanText: 'Witch', iText: 'Legendary', emText: '1.20%' },
  { spanText: 'HawaiianLeaf', iText: 'Unique', emText: '1.90%' },
  { spanText: 'MutantFeet', iText: 'Unique', emText: '2.00%' },
  { spanText: 'Clown', iText: 'Unique', emText: '2.50%' },
  { spanText: 'BalletDance', iText: 'Super Rare', emText: '3.60%' },
  { spanText: 'FormalLeather', iText: 'Super Rare', emText: '3.90%' },
  { spanText: 'Street', iText: 'Super Rare', emText: '2.70%' },
  { spanText: 'FancyLaceBoots', iText: 'Rare', emText: '3.60%' },
  { spanText: 'KoreanHanbok', iText: 'Rare', emText: '3.70%' },
  { spanText: 'SantaBoots', iText: 'Rare', emText: '3.70%' },
  { spanText: 'Blueconverse', iText: 'Uncommon', emText: '5.90%' },
  { spanText: 'line', iText: 'Uncommon', emText: '8.20%' },
  { spanText: 'BasicBoots', iText: 'Uncommon', emText: '6.70%' },
  { spanText: 'Superman', iText: 'Uncommon', emText: '6.30%' },
  { spanText: 'Basic', iText: 'Common', emText: '40.60%' },
];

let hairArray: IAcc[] = [
  { spanText: 'FlowerCrown', iText: 'Legendary', emText: '0.80%' },
  { spanText: 'Octopus', iText: 'Legendary', emText: '2.40%' },
  { spanText: 'Mutant', iText: 'Legendary', emText: '1.40%' },
  { spanText: 'BalletHeadpiece', iText: 'Legendary', emText: '1.10%' },
  { spanText: 'AncientGreekCrown', iText: 'Legendary', emText: '1.10%' },
  { spanText: 'WildHair', iText: 'Unique', emText: '2.10%' },
  { spanText: 'ElvisPresleyHair', iText: 'Unique', emText: '1.20%' },
  { spanText: 'Rainbowwig', iText: 'Unique', emText: '1.90%' },
  { spanText: 'TopHat', iText: 'Unique', emText: '1.60%' },
  { spanText: 'GradHat', iText: 'Super Rare', emText: '3.10%' },
  { spanText: 'RabbitEar', iText: 'Super Rare', emText: '3.00%' },
  { spanText: 'ChefHat', iText: 'Super Rare', emText: '2.70%' },
  { spanText: 'SantaHat', iText: 'Super Rare', emText: '3.00%' },
  { spanText: 'WitchHat', iText: 'Rare', emText: '4.20%' },
  { spanText: 'ClownHat', iText: 'Rare', emText: '3.70%' },
  { spanText: 'Crown', iText: 'Rare', emText: '3.30%' },
  { spanText: 'Beret', iText: 'Rare', emText: '3.30%' },
  { spanText: 'Devil', iText: 'Uncommon', emText: '5.90%' },
  { spanText: 'AngelRing', iText: 'Uncommon', emText: '6.60%' },
  { spanText: 'Toque', iText: 'Uncommon', emText: '5.70%' },
  { spanText: 'Whitecap', iText: 'Uncommon', emText: '5.50%' },
  { spanText: 'Basic', iText: 'Common', emText: '36.40%' },
];

let bodyArray: IAcc[] = [
  { spanText: 'KnightsArmor', iText: 'Legendary', emText: '1.30%' },
  { spanText: 'Manssuit', iText: 'Legendary', emText: '1.00%' },
  { spanText: 'WitchDress', iText: 'Legendary', emText: '0.60%' },
  { spanText: 'EgyptianMummy', iText: 'Legendary', emText: '0.90%' },
  { spanText: 'BalletDress', iText: 'Legendary', emText: '1.60%' },
  { spanText: 'hawaiianbikini', iText: 'Unique', emText: '1.40%' },
  { spanText: 'superman', iText: 'Unique', emText: '1.70%' },
  { spanText: 'swimmingsuit', iText: 'Unique', emText: '1.90%' },
  { spanText: 'trenchcoat', iText: 'Unique', emText: '1.80%' },
  { spanText: 'mutant', iText: 'Unique', emText: '1.70%' },
  { spanText: 'Streetlook', iText: 'Super Rare', emText: '3.20%' },
  { spanText: 'Rabbitcostume', iText: 'Super Rare', emText: '1.60%' },
  { spanText: 'AncientGreekDress', iText: 'Super Rare', emText: '2.80%' },
  { spanText: 'SantaCostume', iText: 'Super Rare', emText: '2.00%' },
  { spanText: 'ClownCostume', iText: 'Super Rare', emText: '2.90%' },
  { spanText: 'RockstarCostume', iText: 'Super Rare', emText: '1.80%' },
  { spanText: 'Doctorsgown', iText: 'Rare', emText: '4.70%' },
  { spanText: 'ComfyJacket', iText: 'Rare', emText: '4.70%' },
  { spanText: 'HanbokDress', iText: 'Rare', emText: '4.00%' },
  { spanText: 'Apron', iText: 'Rare', emText: '4.30%' },
  { spanText: 'LifeSaverJacket', iText: 'Rare', emText: '5.30%' },
  { spanText: 'HoodieTop', iText: 'Uncommon', emText: '9.00%' },
  { spanText: 'basicTshirt', iText: 'Uncommon', emText: '7.90%' },
  { spanText: 'turtleneck', iText: 'Uncommon', emText: '6.10%' },
  { spanText: 'basic', iText: 'Common', emText: '25.80%' },
];

const accArray: IAcc[] = [
  {
    spanText: 'trident',
    iText: 'Legendary',
    emText: '0.60%',
  },
  { spanText: 'wand', iText: 'Legendary', emText: '0.90%' },
  { spanText: 'skull', iText: 'Legendary', emText: '0.80%' },
  { spanText: 'cigarette', iText: 'Legendary', emText: '1.10%' },
  { spanText: 'vaccineshot', iText: 'Legendary', emText: '0.80%' },
  { spanText: 'cuestick', iText: 'Unique', emText: '3.20%' },
  { spanText: 'knife', iText: 'Unique', emText: '3.50%' },
  { spanText: 'guitar', iText: 'Unique', emText: '2.80%' },
  { spanText: 'plantpot', iText: 'Unique', emText: '3.30%' },
  { spanText: 'cottoncandy', iText: 'Unique', emText: '2.10%' },
  { spanText: 'donut', iText: 'Super Rare', emText: '4.70%' },
  { spanText: 'beer', iText: 'Super Rare', emText: '5.80%' },
  { spanText: 'gun', iText: 'Super Rare', emText: '5.70%' },
  { spanText: 'wok', iText: 'Super Rare', emText: '4.30%' },
  { spanText: 'bone', iText: 'Super Rare', emText: '4.30%' },
  { spanText: 'beachball', iText: 'Super Rare', emText: '4.60%' },
  { spanText: 'slottedturner', iText: 'Super Rare', emText: '5.30%' },
  { spanText: 'icecream', iText: 'Super Rare', emText: '5.60%' },
  { spanText: 'basic', iText: 'Common', emText: '40.60%' },
];

type IRareType = 'Common' | 'Super Rare' | 'Unique' | 'Uncommon' | 'Legendary' | 'Rare';
const rareType: Record<IRareType, string> = {
  Common: 'txt-purple',
  'Super Rare': 'txt-red',
  Unique: 'txt-rainbow',
  Uncommon: 'txt-lemon',
  Legendary: 'txt-neon',
  Rare: 'txt-mint',
};

function insertImageInArray(dataArr: IAcc[], imageArr: { img: string; name: string }[]) {
  dataArr.forEach((data, i) => {
    imageArr.forEach((img, j) => {
      if (dataArr[i].spanText.toUpperCase() === imageArr[j].name.toUpperCase()) {
        dataArr[i].img = imageArr[j].img;
        return;
      }
    });
  });
  return dataArr;
}

function insertIClassNameInArray(dataArr: IAcc[]) {
  dataArr.forEach((data, i) => {
    dataArr[i].iClassName = rareType[dataArr[i].iText];
  });
  return dataArr;
}

bgArray = insertImageInArray(bgArray, bgImages);
bgArray = insertIClassNameInArray(bgArray);
bgArray.sort((p, n) => parseFloat(p.emText) - parseFloat(n.emText));
shoesArray = insertImageInArray(shoesArray, shoesImages);
shoesArray[shoesArray.length - 1].img = basic_shoes;
shoesArray = insertIClassNameInArray(shoesArray);
shoesArray.sort((p, n) => parseFloat(p.emText) - parseFloat(n.emText));

hairArray = insertImageInArray(hairArray, hairImages);
hairArray = insertIClassNameInArray(hairArray);
hairArray[hairArray.length - 1].img = basic_hair;
hairArray.sort((p, n) => parseFloat(p.emText) - parseFloat(n.emText));

bodyArray = insertImageInArray(bodyArray, bodyImages);
bodyArray = insertIClassNameInArray(bodyArray);
bodyArray[bodyArray.length - 1].img = basic_body;
bodyArray[bodyArray.length - 16].img = mutant_body;
bodyArray[bodyArray.length - 19].img = superman_body;
bodyArray.sort((p, n) => parseFloat(p.emText) - parseFloat(n.emText));

faceArray = insertImageInArray(faceArray, faceImages);
faceArray = insertIClassNameInArray(faceArray);
faceArray.sort((p, n) => parseFloat(p.emText) - parseFloat(n.emText));

accArray.forEach((acc, i) => {
  accImages.forEach((img, j) => {
    if (accArray[i].spanText === accImages[j].name) {
      accArray[i].img = accImages[j].img;
      return;
    }
  });
});

console.log(bodyArray, 'xczzxcxzcxzccxzcxzcxzcxzcxz');

accArray.forEach((acc, i) => {
  accArray[i].iClassName = rareType[accArray[i].iText];
});

accArray.sort((p, n) => parseFloat(p.emText) - parseFloat(n.emText));

export const attr2Lists = [
  {
    title: 'Accessory',
    children: [...accArray],
  },

  {
    title: 'Background',
    children: [...bgArray],
  },

  {
    title: 'Shoes',
    children: [...shoesArray],
  },
  {
    title: 'Hair',
    children: [...hairArray],
  },

  {
    title: 'Body',
    children: [...bodyArray],
  },
  {
    title: 'Face',
    children: [...faceArray],
  },
];

export const DDAY = '2022-5-27 13:00:00';
