import { useEffect, useState } from 'react';

import styled from 'styled-components';
import { Link as ScrollLink } from 'react-scroll';
import { Link, useHistory } from 'react-router-dom';
import useTriggerHooks from '../../../hooks/useTriggerHooks';
import { HashLink } from 'react-router-hash-link';
import { useRecoilState, useRecoilValue } from 'recoil';
import { headerTitle, isPossibleMintAtom } from '../../../store/atoms';
import { routes } from '../../../common/constants';
import close_btn_img from '../../../assets/images/btn_menu-close.png';
import HeaderContentList from '../../atoms/List/HeaderContent';
import momentTimeZone from 'moment-timezone';
import { DDAY } from '../../../constant';
import moment from 'moment';

const MobileNavContainer = styled.div<{ click: boolean }>`
  .mobile-nav {
    left: ${(props) => (props.click ? '0px' : '-100%')};
    transition: all 0.25s;
  }
`;

const headerContent = [
  { title: 'Home', to: '', pathName: '/' },
  { title: 'About', to: '/#about', hashPath: '/#about' },
  { title: 'Editions', to: '/#editions', hashPath: '/#editions' },
  {
    title: 'Attributes',
    to: '/attributes#attributes',
    pathName: '/attributes#attributes',
  },
  { title: 'Roadmap', to: '/#roadmap', hashPath: '#roadmap' },
  { title: 'Marketplace', to: '/#marketplace', hashPath: '#marketplace' },
  { title: 'FAQ', to: '/#faq', hashPath: '#faq' },
];

const ButtonWrapper = styled.button<{ path: boolean }>`
  @media (max-width: 1280px) {
    top: ${(props) => (props.path ? '178px' : '')};
    //top: 100px;
  }
`;

const HomeHeader = () => {
  const [isClick, setIsClick] = useState(false);
  const pathname = useHistory().location.pathname;
  const location = useHistory().location;
  const [trigger, onChangeTrigger] = useTriggerHooks();
  const [title, setTitle] = useRecoilState(headerTitle);
  const [visible, setVisible] = useState('inline-block');
  const isPossibleMint = useRecoilValue(isPossibleMintAtom);
  console.log(location, 'location');
  const { MINT } = routes;
  const checkUrl = (lo: any) => {
    if (lo.hash) {
      const hash = lo.hash.split('#');
      setTimeout(() => {
        setTitle(hash[hash.length - 1].toUpperCase());
      }, 10);
      return;
    }
    if (lo.pathname) {
      const path = lo.pathname.split('/');
      setTitle(path[path.length - 1] === '' ? 'HOME' : path[path.length - 1].toUpperCase());
      return;
    }
  };

  useEffect(() => {
    if (location.hash) {
      const bodyElement = document.querySelector('body')!;
      bodyElement.classList.remove('pop-open');
    }

    checkUrl(location);
  }, [trigger]);

  useEffect(() => {
    setVisible(pathname !== '/mint' ? 'inline-block' : 'none');
  }, [pathname]);

  // useEffect(() => {
  //   const krTime = momentTimeZone(DDAY).tz('Asia/Seoul').format('YYYY-MM-DD HH:mm:ss');
  //
  //   const localTime = moment(DDAY).format('YYYY-MM-DD HH:mm:ss');
  //   const differ = moment(krTime).unix() - moment(localTime).unix();
  //   const isDDay = moment(DDAY).unix() - moment().unix();
  //   console.log(isDDay, 'isDauy', moment().format('YYYY-MM-DD HH:mm:ss'), localTime);
  //   console.log();
  // }, []);
  //
  // console.log(pathname, 'test', pathname !== '/mint', visible);
  return (
    <>
      <header>
        <section className="header-inner">
          <h1>
            <HashLink to="/">Recharge logo</HashLink>
          </h1>
          <nav>
            <ul className="gnb">
              {headerContent.map((item, i) => {
                return (
                  <HeaderContentList
                    title={title}
                    item={item}
                    setTitle={setTitle}
                    onChangeTrigger={onChangeTrigger}
                  />
                );
              })}
            </ul>
          </nav>

          {/*{isPossibleMint && (*/}
          {/*  <Link to={MINT} className="btn-wallet" style={{ cursor: 'pointer', display: visible }}>*/}
          {/*    Mint*/}
          {/*  </Link>*/}
          {/*)}*/}

          {/*<a href="#" target="_blank" className="btn-wallet">*/}
          {/*  Connect Wallet*/}
          {/*</a>*/}
        </section>
      </header>
      <ButtonWrapper
        path={pathname === '/mint'}
        className="btn-menu"
        onClick={() => {
          const content = document.querySelector('#contents')!;
          const html = document.querySelector('html')!;

          content.setAttribute('class', 'dim');
          html.setAttribute('class', 'nav-open');
          setIsClick(!isClick);
        }}
      >
        button
      </ButtonWrapper>
      <MobileNavContainer click={isClick}>
        <div className="mobile-nav">
          <a
            className="close"
            onClick={() => {
              const content = document.querySelector('#contents')!;
              const html = document.querySelector('html')!;
              content.classList.remove('dim');
              html.classList.remove('nav-open');
              setIsClick(!isClick);
            }}
          >
            <img src={close_btn_img} alt="" />
          </a>
          <div className="gnb-list">
            <ul className="gnb">
              {headerContent.map((item, i) => (
                <li
                  key={i}
                  style={{ cursor: 'pointer' }}
                  // className={item.title.toUpperCase() === title ? 'on' : ''}
                  onClick={() => {
                    setTitle(item.title.toUpperCase());
                    onChangeTrigger();
                  }}
                >
                  {item.pathName ? (
                    <HashLink
                      to={item.pathName}
                      onClick={() => {
                        const content = document.querySelector('#contents')!;
                        const html = document.querySelector('html')!;
                        content.classList.remove('dim');
                        html.classList.remove('nav-open');
                        setIsClick(!isClick);
                        setTitle(item.title.toUpperCase());
                        onChangeTrigger();
                      }}
                    >
                      {item.title}
                    </HashLink>
                  ) : (
                    <HashLink
                      to={item.to}
                      smooth={true}
                      onClick={() => {
                        const content = document.querySelector('#contents')!;
                        const html = document.querySelector('html')!;
                        content.classList.remove('dim');
                        html.classList.remove('nav-open');
                        setIsClick(!isClick);
                      }}
                    >
                      {item.title}
                    </HashLink>
                  )}
                </li>
              ))}
              {/*{pathname !== '/mint' && (*/}
              {/*  <li>*/}
              {/*    <a*/}
              {/*      onClick={() => {*/}
              {/*        const content = document.querySelector('#contents')!;*/}
              {/*        const html = document.querySelector('html')!;*/}
              {/*        content.classList.remove('dim');*/}
              {/*        html.classList.remove('nav-open');*/}
              {/*        setIsClick(!isClick);*/}
              {/*        alert('Please connect from PC');*/}
              {/*      }}*/}
              {/*      className="btn-wallet"*/}
              {/*    >*/}
              {/*      Mint*/}
              {/*    </a>*/}
              {/*  </li>*/}
              {/*)}*/}
            </ul>
            {/*<div className="btn-sns">*/}
            {/*  <ul>*/}
            {/*    <li>*/}
            {/*      <a href="#">*/}
            {/*        <img src={ico_footer_sns01_img} alt="" />*/}
            {/*      </a>*/}
            {/*    </li>*/}
            {/*    <li>*/}
            {/*      <a href="#">*/}
            {/*        <img src={ico_footer_sns02_img} alt="" />*/}
            {/*      </a>*/}
            {/*    </li>*/}
            {/*    <li>*/}
            {/*      <a href="#">*/}
            {/*        <img src={ico_footer_sns03_img} alt="" />*/}
            {/*      </a>*/}
            {/*    </li>*/}
            {/*    <li>*/}
            {/*      <a href="#">*/}
            {/*        <img src={ico_footer_sns04_img} alt="" />*/}
            {/*      </a>*/}
            {/*    </li>*/}
            {/*  </ul>*/}
            {/*</div>*/}
          </div>
        </div>
      </MobileNavContainer>
    </>
  );
};

export default HomeHeader;
