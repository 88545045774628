import './App.css';
import { useMemo } from 'react';
import * as anchor from '@project-serum/anchor';
// import Home from './Home';

import { clusterApiUrl } from '@solana/web3.js';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletWallet,
  getSolletExtensionWallet,
} from '@solana/wallet-adapter-wallets';

import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletDialogProvider } from '@solana/wallet-adapter-material-ui';

import { ThemeProvider, createTheme } from '@material-ui/core';
import { RecoilRoot } from 'recoil';
import HomeHeader from './Components/templetes/Home/Header';
import HomeFooter from './Components/templetes/Home/Footer';
import { PublicRouter } from './common/router';

const theme = createTheme({
  palette: {
    type: 'dark',
  },
});

const getCandyMachineId = (): anchor.web3.PublicKey | undefined => {
  try {
    const candyMachineId = new anchor.web3.PublicKey(process.env.REACT_APP_CANDY_MACHINE_ID!);

    return candyMachineId;
  } catch (e) {
    console.log('Failed to construct CandyMachineId', e);
    return undefined;
  }
};

const candyMachineId = getCandyMachineId();
const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;
const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(
  rpcHost ? rpcHost : anchor.web3.clusterApiUrl('devnet')
);

const startDateSeed = parseInt(process.env.REACT_APP_CANDY_START_DATE!, 10);
const txTimeoutInMilliseconds = 30000;

const App = () => {
  console.log('endpoint', network);
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      // getSolflareWallet(),
      // getSlopeWallet(),
      // getSolletWallet({ network }),
      // getSolletExtensionWallet({ network }),
    ],
    []
  );

  return (
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <ConnectionProvider endpoint={endpoint}>
          {/*<WalletProvider wallets={wallets} autoConnect>*/}
          {/*  <WalletDialogProvider>*/}
          <HomeHeader />
          <PublicRouter
            candyMachineId={candyMachineId}
            connection={connection}
            startDate={startDateSeed}
            txTimeout={txTimeoutInMilliseconds}
            rpcHost={rpcHost}
            wallets={wallets}
          />
          <HomeFooter />
          <div id="modal" />

          {/*  </WalletDialogProvider>*/}
          {/*</WalletProvider>*/}
        </ConnectionProvider>
      </ThemeProvider>
    </RecoilRoot>
  );
};

export default App;
