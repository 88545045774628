import styled from 'styled-components';
import close_btn from '../../../assets/images/btn_menu-close.png';
import { Animate } from 'react-simple-animate';
import React, { FC } from 'react';
import big_close_btn from '../../../assets/images/btn_menu-close-big.png';

const ModalWrapper = styled.div`
  position: absolute;
  width: 400px;
  //padding: 16px;
  height: auto;
  top: 40%;
  left: 50%;
  border-radius: 40px;
  border: solid 2px #fff;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  box-shadow: 0 0 8px 9px rgba(106, 64, 246, 0.7), 0 0 4px 4px rgba(161, 0, 232, 0.5),
    inset 0 0 3px 2px #ffc9fb;
  -webkit-box-shadow: 0 0 8px 9px rgba(106, 64, 246, 0.7), 0 0 4px 4px rgba(161, 0, 232, 0.5),
    inset 0 0 3px 2px #ffc9fb;
  -ms-box-shadow: 0 0 8px 9px rgba(106, 64, 246, 0.7), 0 0 4px 4px rgba(161, 0, 232, 0.5),
    inset 0 0 3px 2px #ffc9fb;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  .txt-rainbow {
    background-image: linear-gradient(
      98deg,
      #ff7979 4%,
      #efbf79 18%,
      #e1d27a 31%,
      #82d37a 44%,
      #7ab8b7 61%,
      #534bd1 74%,
      #ec69ff 88%,
      #ff7f7f 98%
    );
  }
  .dim {
    height: 150%;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(44, 42, 51, 0.5);
  }

  .pop-close {
    position: absolute;
    height: 50px;
    top: 20px;
    right: 48px;
    > img {
      height: 30px !important;
    }
  }

  .edition-wrap {
    padding: 20px 56px 54px;
    border-radius: 32px;
    box-shadow: inset 0 0 3px 2px #ffc9fb;
    -webkit-box-shadow: inset 0 0 3px 2px #ffc9fb;
    -ms-box-shadow: inset 0 0 3px 2px #ffc9fb;
    background: #04031a;
    h4 {
      text-shadow: 0 0 6px #a03fd3, 0 0 2px #4b2cb2, 0 0 2px #4b2cb2;
      -webkit-text-stroke: 0.5px rgba(167, 44, 178, 0.37);
      font-size: 16px;
      font-weight: 600;
      line-height: 1.54;
      color: #fff;
    }
    p {
      margin-top: 20px;
      font-size: 14px;
      line-height: 1.71;
      color: #b3b2d4;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      //> img {
      //  margin-top: 20px;
      //  margin-right: 20px;
      //}
    }
    .edit-cont01 {
      margin: 40px 0;
      padding: 40px 0 32px;
      border-top: 1px solid #574b87;
      border-bottom: 1px solid #574b87;
      .small-tit {
        font-size: 18px;
        font-weight: 600;
        line-height: 2.22;
        color: #fff;
      }
      li {
        margin-top: 16px;
        font-size: 14px;
        line-height: 1.71;
      }
      li:first-child {
        margin-top: 20px;
      }
      li strong {
        display: inline-block;
        vertical-align: text-top;
        width: 113px;
        font-weight: 500;
        color: #fff;
      }
      li span {
        display: inline-block;
        vertical-align: text-top;
        max-width: 440px;
        color: #b3b2d4;
      }
      .pop-close.big img {
        height: 20px !important;
      }
      li i {
        display: inline-block;
        margin-right: 8px;
        margin-bottom: 6px;
      }
    }
    .edit-cont02 {
      .small-tit {
        font-size: 18px;
        font-weight: 600;
        line-height: 2.22;
        color: #fff;
      }
      .table-box {
        margin-top: 20px;
        border-radius: 16px;
        border: solid 1px #574b87;
      }
      table {
        display: table;
        table-layout: fixed;
        width: 100%;
      }
      th {
        font-size: 12px;
        font-weight: 600;
        line-height: 52px;
        color: #9391c7;
        border-bottom: solid 1px #574b87;
      }
      td:not(td:first-child) {
        font-size: 12px;
        line-height: 54px;
        text-align: center;
        color: #fff;
      }
      td:first-child {
        padding-left: 20px;
      }
      .txt-neon {
        color: #fff;
      }
      tbody tr:nth-child(odd) {
        background: #14112f;
      }
      tfoot {
        border-top: solid 1px #574b87;
      }
      tfoot td {
        font-weight: 600;
      }
      tfoot td:first-child {
        color: #9391c7;
        text-align: center;
      }
    }
  }
  @media (max-width: 1280px) {
    transform: none;
    top: 24px;
    left: 0;
    width: auto;
    padding: 12px;
    margin: 0 20px;
    .pop-close {
      top: 28px;
      right: 28px;
    }
    .pop-close.big {
      top: auto;
      bottom: -88px;
      right: auto;
      left: 50%;
      margin-left: -28px;
    }
    .pop-close.big img {
      height: 20px;
    }
    .edition-wrap {
      padding: 84px 20px 22px;
      h4 {
        width: 200px;
      }
      p {
        margin-top: 32px;

        //> img {
        //  margin-right: 15px;
        //}
      }
      li span {
        max-width: 140px;
        color: #b3b2d4;
      }

      .edit-cont02 .table-box {
        overflow: hidden;
      }

      .edit-cont02 .table-box li {
        margin-top: 0;
        padding: 20px 16px 0;
        font-size: 13px;
        line-height: 1.85;
      }

      .edit-cont02 .table-box li:nth-child(odd) {
        background: #14112f;
      }

      .edit-cont02 th {
        font-size: 11px;
        font-weight: 600;
        line-height: 2.18;
        border-bottom: none;
      }

      .edit-cont02 td:not(td:first-child) {
        font-size: 13px;
        line-height: 1.85;
        text-align: left;
        color: #fff;
      }

      .edit-cont02 td:first-child {
        padding-left: 0;
      }

      .edit-cont02 td {
        padding-bottom: 20px;
        color: #fff;
      }

      .edit-cont02 tbody tr:nth-child(odd) {
        background: none;
      }

      .edit-cont02 tfoot {
        border-top: solid 1px #574b87;
      }

      .edit-cont02 tfoot td {
        font-weight: 600;
      }

      .edit-cont02 tfoot td:first-child {
        color: #9391c7;
        text-align: center;
      }
    }
  }
`;

const NftImageWrapper = styled.img<{ margin: boolean; normalMargin: boolean }>`
  margin-top: 20px;
  margin-right: ${(props) => (props.normalMargin ? '0px' : '20px')};
  @media (max-width: 1280px) {
    margin-right: ${(props) => (props.margin ? '15px' : '0px')};
  }
`;

type ModalProps = {
  closeModal: () => void;
  text: string;
};

const InfoModal: FC<ModalProps> = ({ closeModal, text }) => {
  return (
    <Animate play start={{ opacity: 0 }} end={{ opacity: 1 }}>
      <ModalWrapper>
        <a
          className="pop-close"
          onClick={() => {
            const bodyElement = document.querySelector('body')!;
            bodyElement.classList.remove('pop-open');
            closeModal();
          }}
        >
          <img style={{ height: '50px', cursor: 'pointer' }} src={close_btn} alt="" />
        </a>

        <a
          className="pop-close big mobile"
          onClick={() => {
            const bodyElement = document.querySelector('body')!;
            bodyElement.classList.remove('pop-open');
            closeModal();
          }}
        >
          <img src={big_close_btn} alt="" />
        </a>

        <div className="edition-wrap">
          <h4 style={{ marginTop: '50px', textAlign: 'center' }}>{text}</h4>
        </div>
      </ModalWrapper>
    </Animate>
  );
};

export default InfoModal;
