import { Route, Switch } from 'react-router-dom';
import * as anchor from '@project-serum/anchor';

import HomePage from '../pages/Home';
import { routes } from './constants';
import AttributePage from '../pages/Attribute';
import MintPage from '../pages/Mint';
import { useRecoilState } from 'recoil';
import { candyMachineProps, isPossibleMintAtom, walletAutoConnect } from '../store/atoms';
import { CandyMachineProps } from '../types/candyMachine';
import { WalletProvider } from '@solana/wallet-adapter-react';
import { WalletDialogProvider } from '@solana/wallet-adapter-material-ui';
import { useState } from 'react';
import PiggyCellPage from '../pages/PiggyCell';

export const PublicRouter = (data: CandyMachineProps) => {
  const { HOME, ATTR, MINT, PIGGYCELL } = routes;
  const [walletConnection, setWalletConnection] = useRecoilState(walletAutoConnect);
  const [isPossibleMint, setIsPossibleMint] = useRecoilState(isPossibleMintAtom);
  // setCandyMachineAtom({
  //   candyMachineId: props.candyMachineId,
  //   connection: props.connection,
  //   startDate: props.startDate,
  //   txTimeout: props.txTimeout,
  //   rpcHost: props.rpcHost,
  // });

  return (
    <>
      <Switch>
        <Route exact path={HOME} component={HomePage} />
        <Route exact path={ATTR} component={AttributePage} />
        {isPossibleMint ? (
          <WalletProvider wallets={data.wallets} autoConnect>
            <WalletDialogProvider>
              <Route exact path={MINT} render={(props) => <MintPage props={data} />} />
              <Route exact path={PIGGYCELL} render={(props) => <PiggyCellPage props={data} />} />
            </WalletDialogProvider>
          </WalletProvider>
        ) : (
          <>
            <Route exact path={MINT} render={(props) => <MintPage props={data} />} />
            <Route exact path={PIGGYCELL} render={(props) => <PiggyCellPage props={data} />} />
          </>
        )}
      </Switch>
    </>
  );
};
