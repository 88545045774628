import { useEffect, useMemo, useState, useCallback } from 'react';
import * as anchor from '@project-serum/anchor';

import styled from 'styled-components';
import { Container, Snackbar } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Alert from '@material-ui/lab/Alert';
import { LAMPORTS_PER_SOL, PublicKey } from '@solana/web3.js';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletDialogButton } from '@solana/wallet-adapter-material-ui';
import {
  awaitTransactionSignatureConfirmation,
  CandyMachineAccount,
  CANDY_MACHINE_PROGRAM,
  getCandyMachineState,
  mintOneToken,
} from '../../candy-machine';
import { AlertState, formatNumber } from '../../utils';

import { MintButton } from '../../Components/atoms/Button/MintButton';
import { GatewayProvider } from '@civic/solana-gateway-react';
import { UseInterval } from '../../hooks/useInterval';
import { CandyMachineProps } from '../../types/candyMachine';
import { useRecoilState } from 'recoil';
import { isPossibleMintAtom, walletAutoConnect } from '../../store/atoms';

import pig_gif from '../../assets/images/Attributes_2.gif';
import moment from 'moment';
import { DDAY } from '../../constant';
import { completeMint, getMintingStatus } from '../../api';
import useModal from '../../hooks/useModal';
import InfoModal from '../../Components/templetes/Modal/infoModal';

const ConnectButton = styled(WalletDialogButton)`
  padding: 0 35px;
  border-radius: 56px;
  box-shadow: 0 13px 17px 0 rgba(119, 87, 239, 0.2);
  -webkit-box-shadow: 0 13px 17px 0 rgba(119, 87, 239, 0.2);
  -ms-box-shadow: 0 13px 17px 0 rgba(119, 87, 239, 0.2);
  background-image: linear-gradient(94deg, #3461ff 8%, #8454eb 93%);
  font-size: 16px;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: 0.32px;
  color: #fff;
  float: none;
  display: block;
  width: 200px;
  margin: 32px auto 0;
  height: 48px;
  > span {
    margin-top: 0px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: 0.32px;
    color: #fff;
  }
`;

const MintContainer = styled.div`
  margin-top: 32px;
  border-radius: 56px;
`; // add your owns styles here

export interface HomeProps {
  candyMachineId?: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  txTimeout: number;
  rpcHost: string;
}

const MintPage = ({ props }: any) => {
  const [walletConnection, setWalletConnection] = useRecoilState(walletAutoConnect);
  const [isPossibleMint, setIsPossibleMint] = useRecoilState(isPossibleMintAtom);
  const { ModalPortal, openModal, closeModal } = useModal();
  const {
    ModalPortal: InfoPortal,
    openModal: infoOpenModal,
    closeModal: infoCloseModal,
  } = useModal();

  const [balance, setBalance] = useState(0.0);
  const [isUserMinting, setIsUserMinting] = useState(false);
  const [candyMachine, setCandyMachine] = useState<CandyMachineAccount>();
  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: '',
    severity: undefined,
  });
  const rpcUrl = props.rpcHost;
  const wallet = useWallet();

  const anchorWallet = useMemo(() => {
    if (!wallet || !wallet.publicKey || !wallet.signAllTransactions || !wallet.signTransaction) {
      return;
    }

    return {
      publicKey: wallet.publicKey,
      signAllTransactions: wallet.signAllTransactions,
      signTransaction: wallet.signTransaction,
    } as anchor.Wallet;
  }, [wallet]);

  const refreshCandyMachineState = useCallback(async () => {
    if (!anchorWallet) {
      return;
    }
    if (props.candyMachineId) {
      try {
        const cndy = await getCandyMachineState(
          anchorWallet,
          props.candyMachineId,
          props.connection!
        );
        setCandyMachine(cndy);
      } catch (e) {
        console.log('There was a problem fetching Candy Machine state');
        console.log(e);
      }
    }
  }, [anchorWallet, props.candyMachineId, props.connection]);

  const onMint = async () => {
    const isMintingCheck = await getMintingStatus({ address: String(wallet.publicKey) });
    if (isMintingCheck.mint >= 1) {
      openModal();
      return;
    }
    console.log(isMintingCheck, 'isMintingCheck');

    try {
      setIsUserMinting(true);
      document.getElementById('#identity')?.click();
      if (wallet.connected && candyMachine?.program && wallet.publicKey) {
        const mintTxId = (await mintOneToken(candyMachine, wallet.publicKey))[0];
        console.log(mintTxId, 'mintTxID');

        let status: any = { err: true };
        if (mintTxId) {
          const result = await completeMint({ txid: mintTxId, address: String(wallet.publicKey) });
          console.log(result, 'result');
          status = await awaitTransactionSignatureConfirmation(
            mintTxId,
            props.txTimeout,
            props.connection,
            true
          );
        }

        if (status && !status.err) {
          setAlertState({
            open: true,
            message: 'Congratulations! Mint succeeded!',
            severity: 'success',
          });
        } else {
          setAlertState({
            open: true,
            message: 'Mint failed! Please try again!',
            severity: 'error',
          });
        }
      }
    } catch (error: any) {
      let message = error.msg || 'Minting failed! Please try again!';
      if (!error.msg) {
        if (!error.message) {
          message = 'Transaction Timeout! Please try again.';
        } else if (error.message.indexOf('0x137')) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf('0x135')) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `Sold Out!`;
          window.location.reload();
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: 'error',
      });
    } finally {
      setIsUserMinting(false);
    }
  };

  // const dDay = '2022-01-26 18:00:00';
  // const currentTime = moment(new Date()).unix();

  // console.log(moment(dDay).unix() - currentTime, moment(dDay).unix(), currentTime, '111');

  useEffect(() => {}, []);

  useEffect(() => {
    let onClass: Element | null = null;
    const bodyElement = document.querySelector('body')!;
    if (document.querySelector('.on')) {
      onClass = document.querySelector('.on');
    }
    const btnWallet = document.querySelector<HTMLElement>('.btn-wallet')!;

    if (btnWallet) btnWallet.style.display = 'none';
    console.log();
    const unixDDAY = moment(DDAY).unix();
    const currentUnixTime = moment(new Date()).unix();

    if (unixDDAY <= currentUnixTime) {
      setIsPossibleMint(true);
    }

    if (onClass) {
      onClass.classList.remove('on');
    }
    bodyElement.classList.remove('pop-open');
    return () => {
      if (onClass) {
        onClass.classList.add('on');
      }

      if (btnWallet) btnWallet.style.display = 'inline-block';
    };
  }, []);

  useEffect(() => {
    refreshCandyMachineState();
  }, [anchorWallet, props.candyMachineId, props.connection, refreshCandyMachineState]);

  useEffect(() => {
    bootstrap();
  }, [wallet.publicKey]);

  useEffect(() => {
    const win = window as any;
    const isChrome = !!win.chrome && (!!win.chrome.webstore || !!win.chrome.runtime);

    if (!win.chrome) {
      infoOpenModal();
    }
  }, []);

  // UseInterval(() => {
  //   const isDDay = moment(DDAY).unix() - moment().unix();
  //   if (isDDay <= 0 && !isPossibleMint) {
  //     setIsPossibleMint(true);
  //     return;
  //   }
  // }, 1000);

  UseInterval(async () => {
    refreshCandyMachineState();
    if (wallet.publicKey && wallet.connected) {
      const result = await props.connection.getBalance(wallet.publicKey);
      const convertSol = result / LAMPORTS_PER_SOL;
      if (convertSol !== balance) {
        setBalance(convertSol);
      }
    }
  }, 5000);

  const bootstrap = async () => {
    if (wallet.publicKey) {
      const result = await props.connection.getBalance(wallet.publicKey);
      setBalance(result / LAMPORTS_PER_SOL);
    }
  };

  console.log(isPossibleMint, 'mint');
  return (
    <div id="contents">
      <article>
        <div className="minting">
          <h4 className="mint-tit">Let’s Mint !</h4>
          {wallet.connected && (
            <h4 className="mint-tit" style={{ color: '#b3b2d4' }}>
              Minting Price :{' '}
              <em className="white"> {formatNumber.asNumber(candyMachine?.state.price!)} SOL</em>
            </h4>
          )}
          <div className="thumb" style={{ width: '245px', height: '245px', background: 'none' }}>
            <img src={pig_gif} alt="" />
          </div>
          {wallet.connected && (
            <>
              <strong>{candyMachine?.state.itemsRemaining}/1000 </strong>
              <span>
                <i className="red">{`${
                  candyMachine?.state.itemsRemaining ? candyMachine?.state.itemsRemaining : ''
                }`}</i>{' '}
                Left to Mint
              </span>
              <span className="small">
                Your wallet balance : <em className="white">{balance.toFixed(5)} SOL</em>
              </span>
            </>
          )}
          {!wallet.connected ? (
            <ConnectButton
              style={{
                marginTop: '32px',
                width: '200px',
                borderRadius: '56px',
                textTransform: 'none',
              }}
              disabled={!isPossibleMint}
            >
              Connect
            </ConnectButton>
          ) : (
            <>
              {/*<Header candyMachine={candyMachine} />*/}
              <MintContainer>
                {candyMachine?.state.isActive &&
                candyMachine?.state.gatekeeper &&
                wallet.publicKey &&
                wallet.signTransaction ? (
                  <GatewayProvider
                    wallet={{
                      publicKey: wallet.publicKey || new PublicKey(CANDY_MACHINE_PROGRAM),
                      //@ts-ignore
                      signTransaction: wallet.signTransaction,
                    }}
                    gatekeeperNetwork={candyMachine?.state?.gatekeeper?.gatekeeperNetwork}
                    clusterUrl={rpcUrl}
                    options={{ autoShowModal: false }}
                  >
                    <MintButton
                      candyMachine={candyMachine}
                      isMinting={isUserMinting}
                      onMint={onMint}
                    />
                  </GatewayProvider>
                ) : (
                  <MintButton
                    candyMachine={candyMachine}
                    isMinting={isUserMinting}
                    onMint={onMint}
                  />
                )}
              </MintContainer>
            </>
          )}
        </div>
      </article>

      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
      </Snackbar>

      <ModalPortal>
        <InfoModal closeModal={closeModal} text="Already Minted!" />
      </ModalPortal>

      <InfoPortal>
        <InfoModal closeModal={infoCloseModal} text="Open with Chrome Browser" />
      </InfoPortal>
    </div>
  );
};

export default MintPage;
