import React, { useState } from 'react';

import { HashLink } from 'react-router-hash-link';
import FadeIn from 'react-fade-in';
import styled from 'styled-components';
import { Animate, AnimateKeyframes, AnimateGroup } from 'react-simple-animate';

import img_editions01 from '../../../assets/images/img_editions01.png';
import img_editions02 from '../../../assets/images/img_editions02.png';
import img_editions03 from '../../../assets/images/img_editions03.png';
import img_editions04 from '../../../assets/images/edition_2_2.png';
import { routes } from '../../../common/constants';
import useModal from '../../../hooks/useModal';
import close_btn from '../../../assets/images/btn_menu-close.png';
import big_close_btn from '../../../assets/images/btn_menu-close-big.png';
import NftListModal from '../Modal/nftListModal';
import PiggyListsModal from '../Modal/piggyListsModal';
import PiggyEdtionsModal from '../Modal/piggyEdtionsModal';

export const PopUpEditionWrapper = styled.div`
  position: absolute;
  width: 712px;
  padding: 16px;
  height: auto;
  top: 140px;
  left: 50%;
  border-radius: 40px;
  border: solid 2px #fff;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  box-shadow: 0 0 8px 9px rgba(106, 64, 246, 0.7), 0 0 4px 4px rgba(161, 0, 232, 0.5),
    inset 0 0 3px 2px #ffc9fb;
  -webkit-box-shadow: 0 0 8px 9px rgba(106, 64, 246, 0.7), 0 0 4px 4px rgba(161, 0, 232, 0.5),
    inset 0 0 3px 2px #ffc9fb;
  -ms-box-shadow: 0 0 8px 9px rgba(106, 64, 246, 0.7), 0 0 4px 4px rgba(161, 0, 232, 0.5),
    inset 0 0 3px 2px #ffc9fb;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  .tr-long-text {
  }
  .long-text {
    line-height: 30px !important;
    height: 180px !important;
    text-align: center !important;
  }

  .long-text-br {
    background: #14112f !important;
  }

  .td-long-text {
    line-height: 40px !important;
  }

  .txt-rainbow {
    background-image: linear-gradient(
      98deg,
      #ff7979 4%,
      #efbf79 18%,
      #e1d27a 31%,
      #82d37a 44%,
      #7ab8b7 61%,
      #534bd1 74%,
      #ec69ff 88%,
      #ff7f7f 98%
    );
  }
  .dim {
    height: 150%;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(44, 42, 51, 0.5);
  }

  .pop-close {
    position: absolute;
    height: 50px;
    top: 48px;
    right: 48px;
  }

  .edition-wrap {
    padding: 72px 56px 54px;
    border-radius: 32px;
    box-shadow: inset 0 0 3px 2px #ffc9fb;
    -webkit-box-shadow: inset 0 0 3px 2px #ffc9fb;
    -ms-box-shadow: inset 0 0 3px 2px #ffc9fb;
    background: #04031a;
    h4 {
      text-shadow: 0 0 6px #a03fd3, 0 0 2px #4b2cb2, 0 0 2px #4b2cb2;
      -webkit-text-stroke: 0.5px rgba(167, 44, 178, 0.37);
      font-size: 26px;
      font-weight: 600;
      line-height: 1.54;
      color: #fff;
    }
    p {
      margin-top: 20px;
      font-size: 14px;
      line-height: 1.71;
      color: #b3b2d4;
    }
    .edit-cont01 {
      margin: 40px 0;
      padding: 40px 0 32px;
      border-top: 1px solid #574b87;
      border-bottom: 1px solid #574b87;
      .small-tit {
        font-size: 18px;
        font-weight: 600;
        line-height: 2.22;
        color: #fff;
      }
      li {
        margin-top: 16px;
        font-size: 14px;
        line-height: 1.71;
      }
      li:first-child {
        margin-top: 20px;
      }
      li strong {
        display: inline-block;
        vertical-align: text-top;
        width: 113px;
        font-weight: 500;
        color: #fff;
      }
      li span {
        display: inline-block;
        vertical-align: text-top;
        max-width: 440px;
        color: #b3b2d4;
      }
      li i {
        display: inline-block;
        margin-right: 8px;
        margin-bottom: 6px;
      }
    }
    .edit-cont02 {
      .small-tit {
        font-size: 18px;
        font-weight: 600;
        line-height: 2.22;
        color: #fff;
      }
      .table-box {
        margin-top: 20px;
        border-radius: 16px;
        border: solid 1px #574b87;
      }
      table {
        display: table;
        table-layout: fixed;
        width: 100%;
      }
      th {
        font-size: 12px;
        font-weight: 600;
        line-height: 52px;
        color: #9391c7;
        border-bottom: solid 1px #574b87;
      }
      td:not(td:first-child) {
        font-size: 12px;
        line-height: 54px;
        text-align: center;
        color: #fff;
      }
      td:first-child {
        padding-left: 20px;
      }
      .txt-neon {
        color: #fff;
      }
      tbody tr:nth-child(odd) {
        background: #14112f;
      }
      tfoot {
        border-top: solid 1px #574b87;
      }
      tfoot td {
        font-weight: 600;
      }
      tfoot td:first-child {
        color: #9391c7;
        text-align: center;
      }
    }
  }
  @media (max-width: 1280px) {
    transform: none;
    top: 24px;
    left: 0;
    width: auto;
    padding: 12px;
    margin: 0 20px;
    .pop-close {
      top: 28px;
      right: 28px;
    }
    .pop-close.big {
      top: auto;
      bottom: -88px;
      right: auto;
      left: 50%;
      margin-left: -28px;
    }
    .pop-close.big img {
      height: 56px;
    }
    .edition-wrap {
      padding: 84px 20px 22px;
      h4 {
        width: 200px;
      }
      p {
        margin-top: 32px;
      }
      li span {
        max-width: 140px;
        color: #b3b2d4;
      }

      .edit-cont02 .table-box {
        overflow: hidden;
      }

      .edit-cont02 .table-box li {
        margin-top: 0;
        padding: 20px 16px 0;
        font-size: 13px;
        line-height: 1.85;
      }

      .edit-cont02 .table-box li:nth-child(odd) {
        background: #14112f;
      }

      .edit-cont02 th {
        font-size: 11px;
        font-weight: 600;
        line-height: 2.18;
        border-bottom: none;
      }

      .edit-cont02 td:not(td:first-child) {
        font-size: 13px;
        line-height: 1.85;
        text-align: left;
        color: #fff;
      }

      .edit-cont02 td:first-child {
        padding-left: 0;
      }

      .edit-cont02 td {
        padding-bottom: 20px;
        color: #fff;
      }

      .edit-cont02 tbody tr:nth-child(odd) {
        background: none;
      }

      .edit-cont02 tfoot {
        border-top: solid 1px #574b87;
      }

      .edit-cont02 tfoot td {
        font-weight: 600;
      }

      .edit-cont02 tfoot td:first-child {
        color: #9391c7;
        text-align: center;
      }
    }
  }
`;

const HomeSectionThree = () => {
  const { ATTR } = routes;
  // const { ModalPortal, openModal, closeModal } = useModal();
  const [modal, setModal] = useState(false);

  const { ModalPortal, openModal, closeModal } = useModal();
  const { ModalPortal: CarPortal, openModal: carOpenModal, closeModal: carCloseModal } = useModal();
  const {
    ModalPortal: PiggyPortal,
    openModal: piggyOpenModal,
    closeModal: piggyCloseModal,
  } = useModal();

  const {
    ModalPortal: PiggyEditionPortal,
    openModal: piggyEditionOpenModal,
    closeModal: piggyEditionCloseModal,
  } = useModal();

  return (
    <>
      <section className="feature02" id="editions">
        <div className="inner-box">
          <h2 className="tit">Editions</h2>
          <ul>
            <li>
              <figure>
                <img src={img_editions01} alt="" />
              </figure>
              <div className="text">
                <strong>Recharge Neon Cars</strong>
                <p>
                  The 1st edition of The Recharge NFT that activates The Recharge NFT ecosystem. The
                  Recharge Neon Car holders can receive multi-additional benefits from The Recharge
                  ecosystem including free NFT airdrops, RCG airdrops, and royalty sharing.
                </p>
                <HashLink
                  to="/#"
                  className="btn-line"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    openModal();
                    const bodyElement = document.querySelector('body')!;
                    bodyElement.classList.add('pop-open');
                  }}
                >
                  <span>Learn More</span>
                </HashLink>
                <a
                  className="btn-full"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    carOpenModal();
                    const bodyElement = document.querySelector('body')!;
                    bodyElement.classList.add('pop-open');
                  }}
                >
                  <span>Neon Cars</span>
                </a>
              </div>
            </li>
            <li>
              <figure>
                <img src={img_editions04} alt="" />
              </figure>
              <div className="text">
                <strong>PiggyCell</strong>
                <p>
                  The second edition will follow ‘Piggy Cell’ which boosts The Recharge NFT holder’s
                  benefits. PiggyCell, Korea’s no. 1 power bank sharing service brand, unveiling its
                  edition of NFTs.
                </p>

                <a
                  style={{ cursor: 'pointer' }}
                  className="btn-line"
                  onClick={() => {
                    piggyEditionOpenModal();
                    const bodyElement = document.querySelector('body')!;
                    bodyElement.classList.add('pop-open');
                  }}
                >
                  <span>Learn More</span>
                </a>

                <a
                  className="btn-full"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    piggyOpenModal();
                    const bodyElement = document.querySelector('body')!;
                    bodyElement.classList.add('pop-open');
                  }}
                >
                  <span>Piggy Cells</span>
                </a>
              </div>
            </li>
            <li>
              <figure>
                <img src={img_editions03} alt="" />
              </figure>
              <div className="text">
                <strong>Coming Soon</strong>
              </div>
            </li>
          </ul>
        </div>

        <ModalPortal>
          <Animate play start={{ opacity: 0 }} end={{ opacity: 1 }}>
            <PopUpEditionWrapper>
              <a
                className="pop-close"
                onClick={() => {
                  const bodyElement = document.querySelector('body')!;
                  bodyElement.classList.remove('pop-open');
                  closeModal();
                }}
              >
                <img style={{ height: '50px', cursor: 'pointer' }} src={close_btn} alt="" />
              </a>
              <a
                className="pop-close big mobile"
                onClick={() => {
                  const bodyElement = document.querySelector('body')!;
                  bodyElement.classList.remove('pop-open');
                  closeModal();
                }}
              >
                <img src={big_close_btn} alt="" />
              </a>
              <div className="edition-wrap">
                <h4>The Recharge Neon Car</h4>
                <p>
                  The Recharge neon car is the first edition of The Recharge NFT. The Recharge neon
                  car is inspired by the latest concept of EV and super cars. It consists of 31
                  sub-parts under 5 sections which generates only 80 top tier NFTs with unique
                  benefits.
                </p>
                <div className="edit-cont01">
                  <strong className="small-tit">When to mint</strong>
                  <ul>
                    <li>
                      <strong>Date</strong>
                      <span>
                        <em className="mobile-br">7 pm EST,</em> 26 January 2022
                      </span>
                    </li>
                    <li>
                      <strong>Price</strong>
                      <span>0.5 SOL</span>
                    </li>
                    <li>
                      <strong>Max Supply</strong>
                      <span>1,000</span>
                    </li>
                    <li>
                      <strong>Rarity</strong>
                      <span>
                        <i className="txt-neon">Legendary (5),</i>
                        <i className="txt-rainbow">Unique (20),</i>
                        <i className="txt-red">Super Rare (55),</i>
                        <i className="txt-mint">Rare (120),</i>
                        <i className="txt-lemon">Uncommon (350),</i>
                        <i className="txt-purple">Common (450)</i>
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="edit-cont02">
                  <strong className="small-tit">Benefits to hold</strong>
                  <div className="table-box pc">
                    <table>
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Rarity</th>
                          <th>Allocation</th>
                          <th>Airdrop</th>
                          <th>NFT drop</th>
                          <th>Whitelist</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="txt-neon">Legendary</td>
                          <td>0.5%</td>
                          <td>5</td>
                          <td>$1,600 each</td>
                          <td>All eligible</td>
                          <td>All eligible</td>
                        </tr>
                        <tr>
                          <td className="txt-rainbow">Unique</td>
                          <td>2%</td>
                          <td>20</td>
                          <td>$400 each</td>
                          <td>All eligible</td>
                          <td>All eligible</td>
                        </tr>
                        <tr>
                          <td className="txt-red">Super Rare</td>
                          <td>5.50%</td>
                          <td>55</td>
                          <td>$144 each</td>
                          <td>All eligible</td>
                          <td>Random</td>
                        </tr>
                        <tr>
                          <td className="txt-mint">Rare</td>
                          <td>12%</td>
                          <td>120</td>
                          <td>None</td>
                          <td>Random</td>
                          <td>Random</td>
                        </tr>
                        <tr>
                          <td className="txt-lemon">Uncommon</td>
                          <td>35%</td>
                          <td>350</td>
                          <td>None</td>
                          <td>Random</td>
                          <td>Random</td>
                        </tr>
                        <tr>
                          <td className="txt-neon">Common</td>
                          <td>45%</td>
                          <td>450</td>
                          <td>None</td>
                          <td>Random</td>
                          <td>Random</td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td>Total</td>
                          <td>100%</td>
                          <td>1,000</td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  <div className="table-box mobile">
                    <ul>
                      <li>
                        <table>
                          <tbody>
                            <tr>
                              <th>Type</th>
                              <th>Rarity</th>
                              <th>Allocation</th>
                            </tr>
                            <tr>
                              <td className="txt-neon">Legendary</td>
                              <td>0.5%</td>
                              <td>5</td>
                            </tr>
                            <tr>
                              <th>Airdrop</th>
                              <th>NFT drop</th>
                              <th>Whitelist</th>
                            </tr>
                            <tr>
                              <td>$1,600 each</td>
                              <td>All eligible</td>
                              <td>All eligible</td>
                            </tr>
                          </tbody>
                        </table>
                      </li>
                      <li>
                        <table>
                          <tbody>
                            <tr>
                              <th>Type</th>
                              <th>Rarity</th>
                              <th>Allocation</th>
                            </tr>
                            <tr>
                              <td className="txt-rainbow">Unique</td>
                              <td>2%</td>
                              <td>20</td>
                            </tr>
                            <tr>
                              <th>Airdrop</th>
                              <th>NFT drop</th>
                              <th>Whitelist</th>
                            </tr>
                            <tr>
                              <td>$400 each</td>
                              <td>All eligible</td>
                              <td>All eligible</td>
                            </tr>
                          </tbody>
                        </table>
                      </li>
                      <li>
                        <table>
                          <tbody>
                            <tr>
                              <th>Type</th>
                              <th>Rarity</th>
                              <th>Allocation</th>
                            </tr>
                            <tr>
                              <td className="txt-red">Super Rare</td>
                              <td>5.50%</td>
                              <td>55</td>
                            </tr>
                            <tr>
                              <th>Airdrop</th>
                              <th>NFT drop</th>
                              <th>Whitelist</th>
                            </tr>
                            <tr>
                              <td>$144 each</td>
                              <td>All eligible</td>
                              <td>Random</td>
                            </tr>
                          </tbody>
                        </table>
                      </li>
                      <li>
                        <table>
                          <tbody>
                            <tr>
                              <th>Type</th>
                              <th>Rarity</th>
                              <th>Allocation</th>
                            </tr>
                            <tr>
                              <td className="txt-mint">Rare</td>
                              <td>12%</td>
                              <td>120</td>
                            </tr>
                            <tr>
                              <th>Airdrop</th>
                              <th>NFT drop</th>
                              <th>Whitelist</th>
                            </tr>
                            <tr>
                              <td>None</td>
                              <td>Random</td>
                              <td>Random</td>
                            </tr>
                          </tbody>
                        </table>
                      </li>
                      <li>
                        <table>
                          <tbody>
                            <tr>
                              <th>Type</th>
                              <th>Rarity</th>
                              <th>Allocation</th>
                            </tr>
                            <tr>
                              <td className="txt-lemon">Uncommon</td>
                              <td>35%</td>
                              <td>350</td>
                            </tr>
                            <tr>
                              <th>Airdrop</th>
                              <th>NFT drop</th>
                              <th>Whitelist</th>
                            </tr>
                            <tr>
                              <td>None</td>
                              <td>Random</td>
                              <td>Random</td>
                            </tr>
                          </tbody>
                        </table>
                      </li>
                      <li>
                        <table>
                          <tbody>
                            <tr>
                              <th>Type</th>
                              <th>Rarity</th>
                              <th>Allocation</th>
                            </tr>
                            <tr>
                              <td className="txt-neon">Common</td>
                              <td>45%</td>
                              <td>450</td>
                            </tr>
                            <tr>
                              <th>Airdrop</th>
                              <th>NFT drop</th>
                              <th>Whitelist</th>
                            </tr>
                            <tr>
                              <td>None</td>
                              <td>Random</td>
                              <td>Random</td>
                            </tr>
                          </tbody>
                        </table>
                      </li>
                      <li>
                        <table>
                          <tbody>
                            <tr>
                              <th>Total</th>
                              <th>Rarity</th>
                              <th>Allocation</th>
                            </tr>
                            <tr>
                              <td></td>
                              <td>100%</td>
                              <td>1,000</td>
                            </tr>
                          </tbody>
                        </table>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </PopUpEditionWrapper>
          </Animate>
        </ModalPortal>

        <CarPortal>
          <NftListModal closeModal={carCloseModal} />
        </CarPortal>

        <PiggyPortal>
          <PiggyListsModal closeModal={piggyCloseModal} />
        </PiggyPortal>

        <PiggyEditionPortal>
          <PiggyEdtionsModal closeModal={piggyEditionCloseModal} />
        </PiggyEditionPortal>
      </section>
    </>
  );
};

export default HomeSectionThree;
