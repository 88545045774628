import { FC, useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import classNames from 'classnames';
import styled from 'styled-components';

interface Props {
  title: string;
  item: any;
  setTitle: any;
  onChangeTrigger: any;
}

const HashLinkWrapper = styled(HashLink)`
  :after {
    background: url('') !important;
  }
`;

const HeaderContentList: FC<Props> = ({ title, item, setTitle, onChangeTrigger }) => {
  const [visible, setVisible] = useState(false);
  return (
    <li
      style={{ cursor: 'pointer' }}
      // className={item.title.toUpperCase() === title ? 'on' : ''}
      className={classNames({
        on: item.title.toUpperCase() === title,
        'select-box': item.title === 'Attributes',
      })}
      onMouseLeave={() => {
        setVisible(false);
      }}
      onClick={() => {
        setTitle(item.title.toUpperCase());
        onChangeTrigger();
      }}
    >
      {item.title === 'Attributes' ? (
        <>
          <a
            onMouseOver={() => {
              setVisible(true);
            }}
          >
            {item.title}
          </a>
          <div style={{ display: visible ? 'block' : 'none' }}>
            <ul>
              <li>
                <HashLinkWrapper
                  style={{ background: '' }}
                  onClick={() => {
                    if (item.title === 'ATTRIBUTES') {
                      return;
                    }
                    setTitle(item.title.toUpperCase());
                    onChangeTrigger();
                  }}
                  to={item.pathName}
                >
                  Edition 1
                </HashLinkWrapper>
              </li>
              <li>
                <HashLinkWrapper
                  style={{ background: '' }}
                  onClick={() => {
                    if (item.title === 'ATTRIBUTES') {
                      return;
                    }
                    setTitle(item.title.toUpperCase());
                    onChangeTrigger();
                  }}
                  to="/attributes/piggycell"
                >
                  Edition 2
                </HashLinkWrapper>
              </li>
            </ul>
          </div>
        </>
      ) : item.pathName ? (
        <HashLink
          onClick={() => {
            setTitle(item.title.toUpperCase());
            onChangeTrigger();
          }}
          to={item.pathName}
        >
          {item.title}
        </HashLink>
      ) : (
        <HashLink to={item.to} smooth={true}>
          {item.title}
        </HashLink>
      )}
    </li>
  );
};

export default HeaderContentList;
