import { ModalProps } from './piggyListsModal';
import React, { FC } from 'react';
import { Animate } from 'react-simple-animate';
import close_btn from '../../../assets/images/btn_menu-close.png';
import big_close_btn from '../../../assets/images/btn_menu-close-big.png';
import { PopUpEditionWrapper } from '../Home/SectionThree';

const PiggyEditionsModal: FC<ModalProps> = ({ closeModal }) => {
  return (
    <>
      <Animate play start={{ opacity: 0 }} end={{ opacity: 1 }}>
        <PopUpEditionWrapper>
          <a
            className="pop-close"
            onClick={() => {
              const bodyElement = document.querySelector('body')!;
              bodyElement.classList.remove('pop-open');
              closeModal();
            }}
          >
            <img style={{ height: '50px', cursor: 'pointer' }} src={close_btn} alt="" />
          </a>
          <a
            className="pop-close big mobile"
            onClick={() => {
              const bodyElement = document.querySelector('body')!;
              bodyElement.classList.remove('pop-open');
              closeModal();
            }}
          >
            <img src={big_close_btn} alt="" />
          </a>
          <div className="edition-wrap">
            <h4>The Recharge PiggyCell</h4>
            <p>
              Piggy Cell is the second NFT edition created by Recharge labs, has an edition of 1,000
              uniquely generated and collectible Piggy cells, represents a connection between users
              and collectible concept art, boosting the Recharge Ecosystem.
            </p>
            <div className="edit-cont01">
              <strong className="small-tit">When to mint</strong>
              <ul>
                <li>
                  <strong>Date</strong>
                  <span>2022 May 27th (Friday)</span>
                </li>

                <li>
                  <strong>Time</strong>
                  <div>
                    <strong>- Pre-mint:</strong>
                    <span>
                      <em>8pm —10pm (EST)</em>
                    </span>
                  </div>

                  <div>
                    <strong>- Whitelisted:</strong>
                    <span>
                      <em>11pm — 3am (EST)</em>
                    </span>
                  </div>

                  <div>
                    <strong>- Free-mint:</strong>
                    <span>
                      <em>4am (EST) till all Piggies are minted</em>
                    </span>
                  </div>
                </li>
                <li>
                  <strong>Price</strong>
                  <span>Free</span>
                </li>
                <li>
                  <strong>Max Supply</strong>
                  <span>1,000</span>
                </li>
                <li>
                  <strong>Rarity</strong>
                  <span>
                    <i className="txt-neon">Legendary (5),</i>
                    <i className="txt-rainbow">Unique (20),</i>
                    <i className="txt-red">Super Rare (55),</i>
                    <i className="txt-mint">Rare (120),</i>
                    <i className="txt-lemon">Uncommon (350),</i>
                    <i className="txt-purple">Common (450)</i>
                  </span>
                </li>
              </ul>
            </div>
            <div className="edit-cont02">
              <strong className="small-tit">Benefits to hold</strong>
              <div className="table-box pc">
                <table>
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Rarity</th>
                      <th>Allocation</th>
                      <th>NFT drop</th>
                      <th>Benefit_1</th>
                      <th>Benefit_2</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="txt-neon">Legendary</td>
                      <td>0.5%</td>
                      <td>5</td>
                      <td className="long-text">Neon car + Additional NFT</td>
                      <td className="long-text">
                        Manufaturing Goods based on PEP(Print your own NFT on Goods)
                      </td>
                      <td className="long-text long-text-br">
                        Provide coupon for Piggycell(Only for Korean, reward might differ by rarity)
                      </td>
                    </tr>
                    <tr>
                      <td className="txt-rainbow">Unique</td>
                      <td>2%</td>
                      <td>20</td>
                      <td className="long-text">Neon car + Additional NFT</td>
                      <td className="long-text">
                        Manufaturing Goods based on PEP(Print your own NFT on Goods)
                      </td>
                      <td className="long-text long-text-br">
                        3rd NFT Whitelist - A seperate membership ticket in discode channel
                      </td>
                    </tr>
                    <tr>
                      <td className="txt-red">Super Rare</td>
                      <td>5.50%</td>
                      <td>55</td>
                      <td>None</td>
                      <td>None</td>
                      <td className="long-text long-text-br">
                        A seperate membership ticket in discode channel{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="txt-mint">Rare</td>
                      <td>12%</td>
                      <td>120</td>
                      <td>None</td>
                      <td>None</td>
                      <td className="long-text long-text-br">
                        Share project progress and grant participation authority for events,
                        operational planning, etc.
                      </td>
                    </tr>
                    <tr>
                      <td className="txt-lemon">Uncommon</td>
                      <td>35%</td>
                      <td>350</td>
                      <td>None</td>
                      <td>None</td>
                      <td className="long-text long-text-br">
                        Additional b enefits will be announced later
                      </td>
                    </tr>
                    <tr>
                      <td className="txt-neon">Common</td>
                      <td>45%</td>
                      <td>450</td>
                      <td>None</td>
                      <td>None</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td>Total</td>
                      <td>100%</td>
                      <td>1,000</td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <div className="table-box mobile">
                <ul>
                  <li>
                    <table>
                      <tbody>
                        <tr>
                          <th>Type</th>
                          <th>Rarity</th>
                          <th>Allocation</th>
                        </tr>
                        <tr>
                          <td className="txt-neon">Legendary</td>
                          <td>0.5%</td>
                          <td>5</td>
                        </tr>
                        <tr>
                          <th>Airdrop</th>
                          <th>NFT drop</th>
                          <th>Whitelist</th>
                        </tr>
                        <tr>
                          <td>$1,600 each</td>
                          <td>All eligible</td>
                          <td>All eligible</td>
                        </tr>
                      </tbody>
                    </table>
                  </li>
                  <li>
                    <table>
                      <tbody>
                        <tr>
                          <th>Type</th>
                          <th>Rarity</th>
                          <th>Allocation</th>
                        </tr>
                        <tr>
                          <td className="txt-rainbow">Unique</td>
                          <td>2%</td>
                          <td>20</td>
                        </tr>
                        <tr>
                          <th>Airdrop</th>
                          <th>NFT drop</th>
                          <th>Whitelist</th>
                        </tr>
                        <tr>
                          <td>$400 each</td>
                          <td>All eligible</td>
                          <td>All eligible</td>
                        </tr>
                      </tbody>
                    </table>
                  </li>
                  <li>
                    <table>
                      <tbody>
                        <tr>
                          <th>Type</th>
                          <th>Rarity</th>
                          <th>Allocation</th>
                        </tr>
                        <tr>
                          <td className="txt-red">Super Rare</td>
                          <td>5.50%</td>
                          <td>55</td>
                        </tr>
                        <tr>
                          <th>Airdrop</th>
                          <th>NFT drop</th>
                          <th>Whitelist</th>
                        </tr>
                        <tr>
                          <td>$144 each</td>
                          <td>All eligible</td>
                          <td>Random</td>
                        </tr>
                      </tbody>
                    </table>
                  </li>
                  <li>
                    <table>
                      <tbody>
                        <tr>
                          <th>Type</th>
                          <th>Rarity</th>
                          <th>Allocation</th>
                        </tr>
                        <tr>
                          <td className="txt-mint">Rare</td>
                          <td>12%</td>
                          <td>120</td>
                        </tr>
                        <tr>
                          <th>Airdrop</th>
                          <th>NFT drop</th>
                          <th>Whitelist</th>
                        </tr>
                        <tr>
                          <td>None</td>
                          <td>Random</td>
                          <td>Random</td>
                        </tr>
                      </tbody>
                    </table>
                  </li>
                  <li>
                    <table>
                      <tbody>
                        <tr>
                          <th>Type</th>
                          <th>Rarity</th>
                          <th>Allocation</th>
                        </tr>
                        <tr>
                          <td className="txt-lemon">Uncommon</td>
                          <td>35%</td>
                          <td>350</td>
                        </tr>
                        <tr>
                          <th>Airdrop</th>
                          <th>NFT drop</th>
                          <th>Whitelist</th>
                        </tr>
                        <tr>
                          <td>None</td>
                          <td>Random</td>
                          <td>Random</td>
                        </tr>
                      </tbody>
                    </table>
                  </li>
                  <li>
                    <table>
                      <tbody>
                        <tr>
                          <th>Type</th>
                          <th>Rarity</th>
                          <th>Allocation</th>
                        </tr>
                        <tr>
                          <td className="txt-neon">Common</td>
                          <td>45%</td>
                          <td>450</td>
                        </tr>
                        <tr>
                          <th>Airdrop</th>
                          <th>NFT drop</th>
                          <th>Whitelist</th>
                        </tr>
                        <tr>
                          <td>None</td>
                          <td>Random</td>
                          <td>Random</td>
                        </tr>
                      </tbody>
                    </table>
                  </li>
                  <li>
                    <table>
                      <tbody>
                        <tr>
                          <th>Total</th>
                          <th>Rarity</th>
                          <th>Allocation</th>
                        </tr>
                        <tr>
                          <td></td>
                          <td>100%</td>
                          <td>1,000</td>
                        </tr>
                      </tbody>
                    </table>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </PopUpEditionWrapper>
      </Animate>
    </>
  );
};

export default PiggyEditionsModal;
