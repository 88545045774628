import img_about_img from '../../../assets/images/img_about.jpg';
import img_about01_img from '../../../assets/images/img_about01.png';
import img_about02_img from '../../../assets/images/img_about02.png';
import img_about03_img from '../../../assets/images/img_about03.png';
import img_about01_content_img from '../../../assets/images/img_about_content-1.png';
import img_about02_content_img from '../../../assets/images/img_about_content-2.png';
import img_about03_content_img from '../../../assets/images/img_about_content-3.png';
import about1 from '../../../assets/images/about_1.png';
import about2 from '../../../assets/images/about_2.png';
import about3 from '../../../assets/images/about_3.png';

import React, { useState } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

const SectionWrapper = styled.section`
  margin-top: 120px;
  @media (max-width: 1280px) {
    margin-top: 30px !important;

    img {
      width: 150px !important;
      height: 150px !important;
    }
  }
`;

const HomeSectionTwo = () => {
  const [currentTab, setCurrentTab] = useState('piggy');
  console.log(currentTab, 'currentTab');
  return (
    <>
      <SectionWrapper className="feature01" id="about" style={{ marginTop: '120px' }}>
        {/*<figure>*/}
        {/*  <img src={img_about_img} alt="" />*/}
        {/*</figure>*/}
        <h2 className="tit">About</h2>

        <ul className="tab">
          <li
            className={classNames({ curr: currentTab === 'piggy' })}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setCurrentTab('piggy');
            }}
          >
            <a>PiggyCell</a>
          </li>
          <li
            style={{ cursor: 'pointer' }}
            className={classNames({ curr: currentTab === 'recharge' })}
            onClick={() => {
              console.log('asdasd');
              setCurrentTab('recharge');
            }}
          >
            <a>Recharge Neon Cars</a>
          </li>
        </ul>

        <div className="cont-box">
          <div className={classNames('cont', { on: currentTab === 'piggy' })}>
            <p>
              Piggy Cell is the second NFT edition created by Recharge labs, has a edition of 1,000
              uniquely generated and collectible Piggy cells, <br /> represents a connection between
              users and collectible concept art, boosting the Recharge Ecosystem.
            </p>
            <ul>
              <li>
                <img style={{ width: '400px' }} src={about1} alt="" />
              </li>
              <li>
                <img style={{ width: '400px' }} src={about2} alt="" />
              </li>
              <li>
                <img style={{ width: '400px' }} src={about3} alt="" />
              </li>
            </ul>
          </div>
          <div className={classNames('cont', { on: currentTab === 'recharge' })}>
            <p>
              The Recharge aims to build an interoperability ecosystem for the play-to-earn space.
              <br /> The Recharge First NFT edition was Neon Car, is 1,000 and it consists of 31
              parts with 5 different sections.
              <br /> After the successful launch of the Neon car edition, The recharge is releasing
              a second edition of NFT on Solana Network.
            </p>
            <ul>
              <li>
                <img src={img_about01_content_img} alt="" />
              </li>
              <li>
                <img src={img_about02_content_img} alt="" />
              </li>
              <li>
                <img src={img_about03_content_img} alt="" />
              </li>
            </ul>
          </div>
        </div>

        <p>{/*<b>The image you see may differ from the actual image.</b>*/}</p>
      </SectionWrapper>
    </>
  );
};

export default HomeSectionTwo;
