import { atom } from 'recoil';
import { CandyMachineProps } from '../types/candyMachine';

export const TimeZoneAtom = atom({
  key: 'timeZone',
  default: 0,
});

export const isPathTrigger = atom({
  key: 'isPathTrigger',
  default: false,
});

export const headerTitle = atom({
  key: 'headerTitle',
  default: '',
});

export const homeModal = atom({
  key: 'homeModal',
  default: true,
});

export const candyMachineProps = atom<any>({
  key: 'candyMachineProps',
  default: {
    candyMachineId: undefined,
    connection: undefined,
    startDate: 0,
    txTimeout: 0,
    rpcHost: '',
  },
});

export const walletAutoConnect = atom<boolean>({
  key: 'walletAutoConnect',
  default: true,
});

export const isPossibleMintAtom = atom<boolean>({
  key: 'isPossibleMintAtom',
  default: false,
});
